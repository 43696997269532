import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../context/UserContext'; 
import './Profile.css'; // Asegúrate de tener los estilos necesarios

const Profile = () => {
  const navigate = useNavigate(); 
  const { logoutUser } = useContext(UserContext); 

  const goToAddProductPage = () => {
    navigate('/add-product'); 
  };

  const goToInventoryPage = () => {
    navigate('/inventory'); 
  };

  const goToTallasPage = () => {
    navigate('/tallas'); 
  };

  const goToModifyCoverPage = () => {
    navigate('/modify-cover'); 
  };

  const goToVentasPage = () => {
    navigate('/ventas'); 
  };

  // Nueva función para redirigir al módulo de edición de SplitScreen
  const goToEditSplitScreenPage = () => {
    navigate('/edit-split-screen'); // Ruta para el módulo de edición de SplitScreen
  };

  const handleLogout = () => {
    logoutUser(); 
  };

  return (
    <div className="profile-container">
      <div className="profile-card">
        <h2>Perfil de Administrador</h2>
        <p>Bienvenida, Anto</p>
        
        <button onClick={goToAddProductPage} className="add-item-button">
          Agregar Item
        </button>

        <button onClick={goToInventoryPage} className="view-inventory-button">
          Ver Inventario
        </button>

        <button onClick={goToTallasPage} className="view-tallas-button">
          Ver Tallas
        </button>

        <button onClick={goToModifyCoverPage} className="modify-cover-button">
          Modificar Portada
        </button>

        <button onClick={goToVentasPage} className="view-ventas-button">
          Ver Ventas
        </button>

        {/* Nuevo botón para editar SplitScreen */}
        <button onClick={goToEditSplitScreenPage} className="edit-split-screen-button">
          Editar SplitScreen
        </button>

        <button onClick={handleLogout} className="logout-button">
          Cerrar Sesión
        </button>
      </div>
    </div>
  );
};

export default Profile;
