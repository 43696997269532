import React, { useState, useEffect } from 'react';
import './MobilePortada.css';
import { useNavigate } from 'react-router-dom';

const MobilePortada = () => {
  const navigate = useNavigate();
  const [images, setImages] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const fetchMobileImages = async () => {
      try {
        const response = await fetch('https://gusitostore.cl/getFullWidthPortadaMovilImages.php'); // API específica para móvil
        const data = await response.json();
        if (Array.isArray(data) && data.length > 0) {
          setImages(data);
        }
      } catch (error) {
        console.error('Error al obtener las imágenes de móvil:', error);
      }
    };
    fetchMobileImages();
  }, []);

  useEffect(() => {
    if (images.length > 0) {
      const interval = setInterval(() => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [images]);

  return (
    <div className="mobile-image-container">
      {images.map((image, index) => (
        <img
          key={image.id}
          src={image.url}
          alt="Portada Móvil"
          className={`mobile-image ${index === currentImageIndex ? 'show' : ''}`}
        />
      ))}
      <button className="shop-now-button" onClick={() => navigate('/shop')}>
        Shop Now
      </button>
    </div>
  );
};

export default MobilePortada;
