import React from 'react';
import './Envios.css';

const Envios = () => {
  return (
    <div className="envios-container">
      <h1>Política de Envíos</h1>
      <p>En Mi Tienda, nos comprometemos a entregar tus pedidos de manera rápida y segura. A continuación, te explicamos nuestras políticas de envío:</p>

      <h2>Costos de Envío</h2>
      <ul>
        <li>Envío estándar (3-5 días hábiles): $3,000</li>
        <li>Envío exprés (1-2 días hábiles): $6,000</li>
        <li>Envío gratuito en compras superiores a $50,000</li>
      </ul>

      <h2>Tiempos de Entrega</h2>
      <p>Los tiempos de entrega pueden variar dependiendo de la ubicación. Realizamos envíos a todo el país. Una vez que tu pedido haya sido enviado, recibirás un número de seguimiento para rastrear tu paquete en todo momento.</p>

      <h2>Política de Devoluciones</h2>
      <p>Si no estás satisfecho con tu compra, puedes devolver los productos en un plazo de 15 días. Los costos de envío de la devolución correrán por cuenta del comprador, a menos que el producto esté defectuoso.</p>

      <h2>Contacto</h2>
      <p>Si tienes alguna duda sobre tu envío, no dudes en contactarnos a través de nuestro formulario de contacto o al correo <a href="mailto:envios@mitienda.com">envios@mitienda.com</a>.</p>
    </div>
  );
};

export default Envios;
