import React, { useState } from 'react';
import './Tienda.css';
import tienda1 from '../img/tienda1.jpg'; // Añadir las fotos de la tienda
import tienda2 from '../img/tienda2.jpg';
import tienda3 from '../img/tienda3.jpg';
import tienda4 from '../img/tienda4.jpg';
import tienda5 from '../img/tienda5.jpg';
import tienda6 from '../img/tienda6.jpg';

const Tienda = () => {
  // Estado para controlar el carrusel
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // Arreglo de imágenes del carrusel
  const images = [tienda1, tienda2, tienda3, tienda4, tienda5, tienda6];

  // Funciones para avanzar y retroceder en el carrusel
  const nextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  return (
    <div className="tienda-container">
      {/* Título principal */}
      <h1 className="tienda-title">NUESTRA TIENDA EN PROVIDENCIA</h1>

      <div className="tienda-content">
        {/* Mitad izquierda con el carrusel de fotos */}
        <div className="tienda-left">
          <h2 className="section-title">FOTOS DE LA TIENDA</h2>
          <div className="carousel-container">
            <button className="carousel-control prev" onClick={prevImage}>
              &#10094;
            </button>
            <img
              src={images[currentImageIndex]}
              alt={`Tienda ${currentImageIndex + 1}`}
              className="carousel-image"
            />
            <button className="carousel-control next" onClick={nextImage}>
              &#10095;
            </button>
          </div>
        </div>

        {/* Mitad derecha con el mapa y el texto */}
        <div className="tienda-right">
          <h2 className="section-title">UBICACIÓN DE LA TIENDA</h2>
          <p className="tienda-description">
            Ubicada en el corazón de Providencia, nuestra tienda ofrece una experiencia de compra única, combinando moda de alta calidad con un servicio excepcional. Estamos en el icónico Drugstore, justo al lado de la estación de metro Providencia. ¡Ven a conocernos y descubre nuestra última colección!
          </p>
          <p><strong>Dirección:</strong> Av. Providencia 1234, Santiago, Chile</p>
          <div className="map-container">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3330.377865663247!2d-70.61424818503444!3d-33.4269009807815!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662cf6178602f6d%3A0x5607e7f09f6b4210!2sDrugstore!5e0!3m2!1ses-419!2scl!4v1692981740804!5m2!1ses-419!2scl"
              width="100%"
              height="450"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              title="Ubicación de la tienda en Providencia"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tienda;
