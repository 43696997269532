import React, { useEffect, useState } from 'react';
import './Ventas.css'; // Asegúrate de tener un archivo CSS para los estilos

const Ventas = () => {
  const [ventas, setVentas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Obtener los datos de las ventas desde el archivo PHP
    fetch('https://gusitostore.cl/getVentas.php')
      .then(response => {
        if (!response.ok) {
          throw new Error('Error al obtener los datos');
        }
        return response.json();
      })
      .then(data => {
        if (data.success === false) {
          throw new Error(data.message);
        }
        setVentas(data);
        setLoading(false);
      })
      .catch(error => {
        setError(error.message);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <p>Cargando ventas...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <div className="ventas-list-container">
      <h2>Historial de Ventas</h2>
      <table className="ventas-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Productos</th>
            <th>Total Compra</th>
            <th>Cantidad Total</th>
            <th>Fecha de Pago</th>
            <th>Costo de Envío</th>
          </tr>
        </thead>
        <tbody>
          {ventas.map((venta) => (
            <tr key={venta.id}>
              <td>{venta.id}</td>
              <td>{venta.productos_nombres || 'Sin productos'}</td> {/* Mostrar los nombres de los productos en lugar de los IDs */}
              <td>
                {parseFloat(venta.total_compra).toLocaleString('es-CL', {
                  style: 'currency',
                  currency: 'CLP',
                })}
              </td>
              <td>{venta.cantidad_total}</td>
              <td>{new Date(venta.fecha_pago).toLocaleString('es-CL')}</td>
              <td>
                {parseFloat(venta.costo_envio).toLocaleString('es-CL', {
                  style: 'currency',
                  currency: 'CLP',
                })}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Ventas;
