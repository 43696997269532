import React, { useEffect, useState } from 'react';
import './ModifyGallery4.css';

const ModifyGallery4 = () => {
  const [galleryIds, setGalleryIds] = useState([]); // IDs actuales en galería
  const [selectedId, setSelectedId] = useState(''); // ID seleccionada
  const [newProductId, setNewProductId] = useState(''); // Nueva ID ingresada
  const [message, setMessage] = useState(''); // Mensaje de éxito o error
  const [loading, setLoading] = useState(true); // Estado de carga
  const [error, setError] = useState(null); // Estado de error
  const [maxIds, setMaxIds] = useState(0); // Máximo de IDs permitidas
  const [products, setProducts] = useState([]); // Productos relacionados

  const fetchGalleryData = () => {
    setLoading(true);
    fetch('https://gusitostore.cl/getGallery4Ids.php')
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (data.error) {
          throw new Error(data.error);
        }
        setGalleryIds(Array.isArray(data.current_ids) ? data.current_ids : []);
        setMaxIds(data.max_ids || 4); // Default max_ids to 4 if undefined
        setProducts(Array.isArray(data.products) ? data.products : []);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching IDs:', error);
        setError('Hubo un problema al cargar las IDs.');
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchGalleryData();
  }, []);

  const handleUpdate = (e) => {
    e.preventDefault();

    if (!newProductId) {
      setMessage('Por favor ingresa una nueva ID.');
      return;
    }

    fetch('https://gusitostore.cl/updateGallery4.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        currentId: selectedId,
        newId: newProductId,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (data.success) {
          setMessage(data.message);
          setSelectedId('');
          setNewProductId('');
          fetchGalleryData();
        } else {
          setMessage('Error: ' + data.message);
        }
      })
      .catch((error) => {
        console.error('Error updating ID:', error);
        setMessage('Error en el servidor.');
      });
  };

  if (loading) {
    return <p className="gallery-loading">Cargando IDs...</p>;
  }

  if (error) {
    return <p className="gallery-error">{error}</p>;
  }

  const canAddNewId = galleryIds.length < maxIds;

  return (
    <div className="modify-gallery4-container">
      <h2 className="gallery4-title">Modificar Galería 4</h2>
      <form onSubmit={handleUpdate} className="gallery4-form">
        <div className="gallery4-form-group">
          <label htmlFor="currentId">Selecciona la ID a modificar:</label>
          <select
            id="currentId"
            value={selectedId}
            onChange={(e) => setSelectedId(e.target.value)}
            className="gallery4-select"
          >
            <option value="">Selecciona una ID</option>
            {products.map((product) => (
              <option key={product.id} value={product.id}>
                ID {product.id} - {product.nombre}
              </option>
            ))}
            {canAddNewId && <option value="add-new">Agregar nueva ID</option>}
          </select>
        </div>

        {selectedId && (
          <div className="gallery4-form-group">
            <label htmlFor="newProductId">Nueva ID:</label>
            <input
              type="text"
              id="newProductId"
              value={newProductId}
              onChange={(e) => setNewProductId(e.target.value)}
              placeholder="Ingresa nueva ID"
              className="gallery4-input"
            />
          </div>
        )}

        <button type="submit" className="gallery4-update-button">
          {selectedId === 'add-new' ? 'Agregar ID' : 'Actualizar ID'}
        </button>
      </form>
      {message && <p className="gallery4-message">{message}</p>}
    </div>
  );
};

export default ModifyGallery4;
