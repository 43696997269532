import React from 'react';
import AllProductsGallery from './AllProductsGallery';

const Shop = () => {
  return (
    <div>
      <AllProductsGallery />
    </div>
  );
};

export default Shop;
