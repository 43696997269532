// App.js
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { UserProvider } from './context/UserContext';
import { CartProvider } from './context/CartContext';
import ImageUpload from './components/ImageUpload'; 
import Nav from './components/Nav';
import Home from './components/Home';
import Shop from './components/Shop';
import Login from './components/Login';
import Profile from './components/Profile';
import Tienda from './components/Tienda';
import Nosotras from './components/Nosotras';
import Gestion from './components/Gestion';
import CategoryGallery from './components/CategoryGallery';
import ProductDetails from './components/ProductDetails';
import AddProduct from './components/AddProduct';
import Inventory from './components/Inventory';
import PaymentModule from './components/PaymentModule';
import PaymentConfirmation from './components/PaymentConfirmation';
import Medidas from './components/Medidas';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Banner from './components/Banner';
import Footer from './components/Footer';
import TermsAndConditions from './components/TermsAndConditions'; 
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import RelatedProducts from './components/RelatedProducts'; 
import Envios from './components/Envios'; 
import TallasPage from './components/TallasPage'; 
import ModifyCover from './components/ModifyCover';
import ModifyGallery4 from './components/ModifyGallery4'; 
import ModifyGallery6 from './components/ModifyGallery6'; 
import ManageGalleryComunidad from './components/ManageGalleryComunidad';
import Ventas from './components/Ventas';
import ImageUploaderModule from './components/ImageUploaderModule'; 
import SplitScreenEditor from './components/SplitScreenEditor'; // Importar el componente SplitScreenEditor

// Manejar scroll al cambiar de ruta
const ScrollHandler = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

function App() {
  return (
    <UserProvider>
      <CartProvider>
        <Router>
          {/* Manejar el scroll cuando la ruta cambia */}
          <ScrollHandler />
          {/* Banner y navegación */}
          <Banner />
          <Nav />

          {/* Rutas */}
          <Routes>
            <Route path="/profile" element={<Profile />} />
            <Route path="/ventas" element={<Ventas />} />
            <Route path="/modify-cover" element={<ModifyCover />} />
            <Route path="/image-upload-module" element={<ImageUploaderModule />} /> 
            <Route path="/upload-image" element={<ImageUpload />} />
            <Route path="/modify-gallery-4" element={<ModifyGallery4 />} />
            <Route path="/modify-gallery-6" element={<ModifyGallery6 />} />
            <Route path="/manage-gallery-comunidad" element={<ManageGalleryComunidad />} />
            <Route path="/edit-split-screen" element={<SplitScreenEditor />} /> {/* Nueva ruta para SplitScreenEditor */}

            {/* Página de inicio */}
            <Route path="/" element={<Home />} />
            
            {/* Página de tienda y categorías */}
            <Route path="/shop" element={<Shop />} />
            <Route path="/shop/accesorios" element={<CategoryGallery category="Accesorios" />} />
            <Route path="/shop/chaquetas-pol" element={<CategoryGallery category="Chaquetas y Polerones" />} />
            <Route path="/shop/faldas-shorts" element={<CategoryGallery category="Faldas y Shorts" />} />
            <Route path="/shop/pantalones" element={<CategoryGallery category="Pantalones" />} />
            <Route path="/shop/tops" element={<CategoryGallery category="Tops" />} />
            <Route path="/shop/verano-forever" element={<CategoryGallery category="Verano Forever" />} />
            
            {/* Página de detalles del producto */}
            <Route 
              path="/product/:id" 
              element={
                <>
                  <ProductDetails />
                  <RelatedProducts category="some-category" />
                </>
              } 
            />
            
            {/* Rutas de usuario */}
            <Route path="/login" element={<Login />} />
            <Route path="/profile" element={<Profile />} />

            {/* Gestión de productos */}
            <Route path="/add-product" element={<AddProduct />} />
            <Route path="/inventory" element={<Inventory />} />
            
            {/* Carrito de compras */}
            <Route path="/paymentmodule" element={<PaymentModule />} />

            {/* Confirmación de pago */}
            <Route path="/payment-confirmation" element={<PaymentConfirmation />} />

            {/* Otras páginas */}
            <Route path="/tienda" element={<Tienda />} />
            <Route path="/nosotras" element={<Nosotras />} />
            <Route path="/gestion" element={<Gestion />} />
            <Route path="/medidas" element={<Medidas />} />
            <Route path="/terminos" element={<TermsAndConditions />} />
            <Route path="/envios" element={<Envios />} />
            <Route path="/tallas" element={<TallasPage />} />
          </Routes>

          {/* Footer y notificaciones */}
          <Footer />
          <ToastContainer />
        </Router>
      </CartProvider>
    </UserProvider>
  );
}

export default App;
