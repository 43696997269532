import React from 'react';
import './Banner.css';

const Banner = () => {
  return (
    <div className="banner-container">
      <div className="banner-content">
        <div className="banner-item">ENVIOS GRATIS SOBRE $100.000 :)</div>
        <div className="banner-item">ENVIOS Y RETIROS ENTRE 5 Y 10 DIAS HÁBILES ❤</div>
      </div>
    </div>
  );
};

export default Banner;
