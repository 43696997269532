import React, { useState, useEffect } from 'react';
import './SplitScreenCard.css';

const SplitScreenCard = () => {
  const [items, setItems] = useState([]);  // Almacenar los productos
  const [currentIndex, setCurrentIndex] = useState(0);  // Control del índice actual
  const [loading, setLoading] = useState(true);  // Estado de carga de productos
  const [error, setError] = useState(null);  // Estado de error en productos
  const [leftImageUrl, setLeftImageUrl] = useState(''); // URL de la imagen de la izquierda

  useEffect(() => {
    // Obtener la URL de la imagen izquierda desde la tabla split
    fetch('https://gusitostore.cl/getSplitImage.php')
      .then((response) => {
        if (!response.ok) {
          throw new Error('Error en la solicitud de la imagen izquierda');
        }
        return response.json();
      })
      .then((data) => {
        if (data.url) {
          setLeftImageUrl(data.url);
        } else {
          throw new Error(data.error || 'No se encontró la imagen.');
        }
      })
      .catch((error) => {
        setError('Error al cargar la imagen de la izquierda');
      });

    // Obtener los productos desde la tabla splitid
    fetch('https://gusitostore.cl/getSplitData.php')
      .then((response) => {
        if (!response.ok) {
          throw new Error('Error en la solicitud de productos');
        }
        return response.json();
      })
      .then((data) => {
        setItems(data);  // Guardar los productos en el estado
        setLoading(false);
      })
      .catch((error) => {
        setError('Hubo un error al cargar los productos');
        setLoading(false);
      });
  }, []);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);  // Cambiar al siguiente producto
  };

  const handleViewProduct = (id) => {
    window.location.href = `/product/${id}`;  // Redirigir a la página del producto
  };

  if (loading) {
    return <p>Cargando productos...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  if (items.length === 0) {
    return <p>No hay productos para mostrar.</p>;
  }

  return (
    <div className="split-screen-unique">
      <div className="split-left-unique">
        {/* Imagen obtenida de la base de datos */}
        {leftImageUrl ? (
          <img src={leftImageUrl} alt="Imagen Grande" className="left-image-unique" />
        ) : (
          <p>Cargando imagen...</p>
        )}
      </div>
      <div className="split-right-unique">
        {items.length > 0 && (
          <>
            <div className="card-image-container-unique">
              <img
                src={items[currentIndex].imagen}  // Mostramos la imagen del producto
                alt={items[currentIndex].nombre}
                className="card-image-unique"
                onError={(e) => {
                  e.target.src = "https://via.placeholder.com/400x400";
                }}
              />
            </div>
            <div className="item-info-card-unique">
              <div className="item-info-card-unique-text">
                <h3 className="item-name-card-unique">{items[currentIndex].nombre}</h3>
                <p className="item-price-card-unique">
                  {parseInt(items[currentIndex].precio).toLocaleString('es-CL', {
                    style: 'currency',
                    currency: 'CLP',
                  })}
                </p>
              </div>
              <div className="item-info-buttons-unique">
                <button
                  className="view-product-button-unique"
                  onClick={() => handleViewProduct(items[currentIndex].id)}
                >
                  Ver producto
                </button>
                <div className="carousel-buttons-unique">
                  <button className="carousel-button-unique right" onClick={handleNext}>
                    Siguiente ›
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default SplitScreenCard;
