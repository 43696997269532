import React, { createContext, useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

// Crear el contexto del carrito
export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState([]);
  const [shippingCost, setShippingCost] = useState(0); // Nueva propiedad para el costo de envío

  // Al cargar la aplicación, verifica si hay items en el localStorage
  useEffect(() => {
    const storedCart = localStorage.getItem('cart');
    if (storedCart) {
      const parsedCart = JSON.parse(storedCart);

      // Verificamos si ha pasado el tiempo de expiración
      const currentTime = new Date().getTime();
      const expirationTime = parsedCart.expirationTime || 0;

      if (currentTime < expirationTime) {
        setCartItems(parsedCart.items);
        setShippingCost(parsedCart.shippingCost || 0); // Recuperar el costo de envío del almacenamiento
      } else {
        localStorage.removeItem('cart'); // Limpiar si ha expirado
      }
    }
  }, []);

  // Función para guardar el carrito y el costo de envío en localStorage con tiempo de expiración
  const saveCartToLocalStorage = (items, shippingCost) => {
    try {
      const expirationTime = new Date().getTime() + 5 * 60 * 1000; // Expira en 5 minutos
      const cartData = {
        items,
        shippingCost, // Guardar el costo de envío
        expirationTime
      };
      localStorage.setItem('cart', JSON.stringify(cartData));
    } catch (error) {
      console.error('Error guardando el carrito en localStorage:', error);
    }
  };

  // Función para agregar un producto al carrito
  const addToCart = (product, quantity = 1) => {
    const existingItem = cartItems.find(item => item.id === product.id && item.size === product.size);

    if (existingItem) {
      const updatedCart = cartItems.map(item =>
        item.id === product.id && item.size === product.size
          ? { ...item, quantity: item.quantity + quantity }
          : item
      );
      setCartItems(updatedCart);
      saveCartToLocalStorage(updatedCart, shippingCost);
    } else {
      const productWithUniqueId = {
        ...product,
        cartId: uuidv4(), // Generar una ID única usando uuid
        quantity: quantity,
      };
      const updatedCart = [...cartItems, productWithUniqueId];
      setCartItems(updatedCart);
      saveCartToLocalStorage(updatedCart, shippingCost);
    }
  };

  // Función para actualizar el costo de envío en el contexto y en localStorage
  const updateShippingCost = (cost) => {
    setShippingCost(cost);
    saveCartToLocalStorage(cartItems, cost); // Guardar también el costo de envío en localStorage
  };

  return (
    <CartContext.Provider
      value={{
        cartItems,
        shippingCost, // Exponer el costo de envío
        addToCart,
        updateShippingCost, // Exponer la función para actualizar el costo de envío
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
