import React, { useEffect, useState } from 'react';
import './ImageGallery.css';

const ImageGallery = () => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Hacer la solicitud para obtener las IDs de los productos a mostrar
    fetch('https://gusitostore.cl/getImages.php')
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setItems(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setError('Hubo un problema al cargar las imágenes.');
        setLoading(false);
      });
  }, []);

  const handleImageClick = (productId) => {
    window.location.href = `/product/${productId}`;
  };

  if (loading) {
    return <p>Cargando imágenes...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className="unique-gallery-containers">
      {items.map((item, index) => (
        <div key={item.id} className="unique-image-container" onClick={() => handleImageClick(item.id)}>
          <img src={item.imagen} alt={item.nombre} className="unique-gallery-image" />
          <img src={item.imagen2} alt={`${item.nombre} Hover`} className="unique-hover-image" />
        </div>
      ))}
    </div>
  );
};

export default ImageGallery;
