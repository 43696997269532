import React, { useState, useEffect, useRef } from 'react';
import './CategoryGallery.css';

// Definición del componente HoverableImage dentro del mismo archivo
const HoverableImage = ({ defaultImage, hoverImage, alt, onClick }) => {
  const [currentImage, setCurrentImage] = useState(defaultImage);

  const handleMouseEnter = () => {
    if (hoverImage) {
      setCurrentImage(hoverImage); // Cambia a hoverImage solo si existe
    }
  };

  const handleMouseLeave = () => {
    setCurrentImage(defaultImage); // Vuelve a la imagen principal al quitar hover
  };

  return (
    <img
      src={currentImage}
      alt={alt}
      className="product-image-renamed"
      onClick={onClick}
      onMouseEnter={hoverImage ? handleMouseEnter : null} // Solo cambiar si existe imagen2
      onMouseLeave={hoverImage ? handleMouseLeave : null} // Solo cambiar si existe imagen2
    />
  );
};

const CategoryGallery = ({ category }) => {
  const [products, setProducts] = useState([]);
  const [visibleProducts, setVisibleProducts] = useState([]);
  const productRefs = useRef([]);

  useEffect(() => {
    // Fetch products from API
    fetch(`https://gusitostore.cl/getProductsByCategory.php?category=${category}`)
      .then(response => response.json())
      .then(data => {
        if (data.message) {
          console.error(data.message);
        } else {
          setProducts(data);
        }
      })
      .catch(error => console.error('Error fetching products:', error));
  }, [category]);

  useEffect(() => {
    const observerOptions = {
      root: null, // Observa el viewport
      rootMargin: '0px',
      threshold: 0.1, // Activa cuando el 10% del elemento está en el viewport
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const index = parseInt(entry.target.dataset.index, 10);
          setVisibleProducts((prevVisibleProducts) => [...prevVisibleProducts, index]);
        }
      });
    }, observerOptions);

    productRefs.current.forEach((productRef) => {
      if (productRef) {
        observer.observe(productRef);
      }
    });

    return () => {
      productRefs.current.forEach((productRef) => {
        if (productRef) {
          observer.unobserve(productRef);
        }
      });
    };
  }, [products]);

  const handleProductClick = (id) => {
    window.location.href = `/product/${id}`; // Forzar la recarga de la página al cambiar de producto
  };

  return (
    <div className="category-gallery-renamed">
      {products.map((product, index) => (
        <div
          key={index}
          className={`product-card-renamed ${visibleProducts.includes(index) ? 'product-card-visible' : ''}`}
          ref={(el) => (productRefs.current[index] = el)} // Guardar referencia de cada producto
          data-index={index}
          style={{ '--order': index % 4 }} // Controla el orden en cada fila para el efecto dominó
        >
          <HoverableImage 
            defaultImage={product.imagen} 
            hoverImage={product.imagen2}  // Pasar imagen2 si existe
            alt={product.nombre} 
            onClick={() => handleProductClick(product.id)} 
          />
          <div className="product-info-renamed">
            <h3 className="product-name-renamed">{product.nombre}</h3>
            <p className="product-price-renamed">
              {parseInt(product.precio).toLocaleString('es-CL', {
                style: 'currency',
                currency: 'CLP',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CategoryGallery;
