import React, { useState, useEffect } from 'react';
import './RelatedProducts.css';
import { useParams } from 'react-router-dom';

const RelatedProducts = () => {
  const [relatedProducts, setRelatedProducts] = useState([]); // Todos los productos relacionados
  const { id } = useParams(); // Obtener el ID del producto actual
  const [currentIndex, setCurrentIndex] = useState(0); // Índice actual para la navegación móvil
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Detectar si es móvil

  useEffect(() => {
    // Obtener productos relacionados desde la API
    fetch(`https://gusitostore.cl/getRelatedProducts.php?product_id=${id}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.message) {
          console.error('No related products found');
        } else {
          setRelatedProducts(data);
        }
      })
      .catch((error) => {
        console.error('Error fetching related products:', error);
      });

    // Escuchar cambios de tamaño de pantalla
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [id]);

  // Función para avanzar al siguiente conjunto de 2 productos en móvil
  const handleNext = () => {
    if (currentIndex < relatedProducts.length - 2) {
      setCurrentIndex(currentIndex + 2);
    } else {
      setCurrentIndex(0); // Volver al inicio si llegamos al final
    }
  };

  // Función para retroceder al conjunto anterior de 2 productos en móvil
  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 2);
    } else {
      setCurrentIndex(Math.max(relatedProducts.length - 2, 0)); // Ir al final si estamos al inicio
    }
  };

  // Determinar los productos visibles en móvil
  const visibleProducts = isMobile
    ? relatedProducts.slice(currentIndex, currentIndex + 2)
    : relatedProducts;

  return (
    <div className="related-products-container">
      <h3 className="related-products-title">PRODUCTOS RELACIONADOS</h3>

      <div className={`related-products-grid ${isMobile ? 'mobile' : 'desktop'}`}>
        {visibleProducts.map((product) => (
          <div
            key={product.id}
            className="related-product-card"
            onClick={() => window.location.href = `/product/${product.id}`}
          >
            <img
              src={product.imagen}
              alt={product.nombre}
              className="related-product-image"
            />
            <div className="related-product-info">
              <h4 className="related-product-name">{product.nombre}</h4>
              <p className="related-product-price">
                {parseInt(product.precio).toLocaleString('es-CL', {
                  style: 'currency',
                  currency: 'CLP',
                  minimumFractionDigits: 0,
                })}
              </p>
            </div>
          </div>
        ))}
      </div>

      {/* Botones de navegación solo visibles en móvil */}
      {isMobile && relatedProducts.length > 2 && (
        <div className="related-products-controls">
          <button className="related-prev-btn" onClick={handlePrevious}>
            ‹
          </button>
          <button className="related-next-btn" onClick={handleNext}>
            ›
          </button>
        </div>
      )}
    </div>
  );
};

export default RelatedProducts;
