import React, { useState } from 'react';
import './Nosotras.css';
import newImage from '../img/new.png'; // Importa la imagen del artículo
import anto1 from '../img/anto1.jpg'; // Imágenes actuales
import anto2 from '../img/anto2.jpg';
import anto3 from '../img/anto3.jpg'; // Nuevas imágenes agregadas
import anto4 from '../img/anto4.jpg';
import anto5 from '../img/anto5.jpg';
import anto6 from '../img/anto6.jpg';

const Nosotras = () => {
  // Estado para controlar el carrusel
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // Arreglo de imágenes del carrusel
  const images = [anto1, anto2, anto3, anto4, anto5, anto6];

  // Funciones para avanzar y retroceder en el carrusel
  const nextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  return (
    <div className="nosotras-container">
      {/* Banner principal */}
      <div className="nosotras-banner">
        <h1>Sobre Nosotras</h1>
      </div>

      {/* Contenedor dividido en dos mitades */}
      <div className="nosotras-content">
        {/* Mitad izquierda con el carrusel */}
        <div className="nosotras-left">
          <h2 className="section-title">Nuestras Fundadoras</h2>
          <div className="carousel-container">
            <button className="carousel-control prev" onClick={prevImage}>
              &#10094;
            </button>
            <img
              src={images[currentImageIndex]}
              alt={`Fundadora ${currentImageIndex + 1}`}
              className="carousel-image"
            />
            <button className="carousel-control next" onClick={nextImage}>
              &#10095;
            </button>
          </div>
        </div>

        {/* Mitad derecha con el texto y el artículo */}
        <div className="nosotras-right">
          <div className="nosotras-section">
            <h2 className="section-title">DISEÑO Y CONFECCIÓN HECHO POR NOSOTRAS</h2>
            <p className="section-text">
              Somos una marca de diseño y confección de vestuario conformada por madre e hija, ambas amantes de la moda y de lo hecho a mano.
            </p>
            <p className="section-text">
              Trabajamos todos los procesos de creación de una prenda desde corte, confección y terminaciones tales como planchado, embolsado, etc.
            </p>
            <p className="section-text">
              Nuestro plus son los estampados propios ya que los trabajamos de forma digital y luego los imprimimos en las telas mediante la técnica de sublimación textil.
            </p>
            <p className="section-text">
              Nuestra idea siempre ha sido crear prendas para que las personas se sientan únicas y también cómodas. Esperamos cumplir con ese objetivo y seguir en constante crecimiento.
            </p>
          </div>
        </div>
      </div>
      <div className="nosotras-article">
          <div className="article">
            <div className='article-link'>
            <h2 className="section-title">Artículos sobre Nuestra Empresa</h2>
              <p className="section-text">
                Recientemente, un artículo destacó el crecimiento de nuestra empresa y cómo nos hemos mantenido fieles a nuestras raíces mientras innovamos con nuestros diseños.
                ¡No te pierdas la historia completa en nuestra sección de prensa!
              </p>
              <a 
                href="https://vistelacalle.com/estilo-urbano-y-colorido-la-propuesta-unica-de-yatehevisto/" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="article-link">
                Leer el artículo completo aquí
              </a>
            </div>
            <img src={newImage} alt="Artículo sobre la empresa" className="article-image" />
          </div>
        </div>
      </div>
  );
};

export default Nosotras;
