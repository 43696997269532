import React from 'react';
import { Instagram } from '@mui/icons-material';
import { FaTiktok } from 'react-icons/fa';
import './Footer.css'; 
import logo from '../img/logo0.png';

const Footer = () => {
  const handleNavigation = (url) => {
    window.location.href = url; // Forzar la recarga de la página
  };

  return (
    <footer className="footer-container">
      <div className="footer-content">
        <div className="footer-section categories">
          <img src={logo} alt="Mi Tienda Logo" className="logo" />
          <h3>Categorías</h3>
          <ul>
            <li onClick={() => handleNavigation('/')}>Home</li>
            <li onClick={() => handleNavigation('/shop')}>Shop</li>
            <li onClick={() => handleNavigation('/nosotras')}>Nosotras</li>
            <li onClick={() => handleNavigation('/tienda')}>Tienda</li>
            <li onClick={() => handleNavigation('/medidas')}>Medidas</li>
          </ul>
        </div>

        <div className="footer-section newsletter">
          <h3>Newsletter</h3>
          <p>Únete para recibir las últimas novedades y descuentos exclusivos.</p>
          <form className="newsletter-form">
            <input type="email" placeholder="Introduce tu email" />
            <button type="submit">Suscribirse</button>
          </form>
        </div>

        <div className="footer-section social">
          <h3>Síguenos</h3>
          <ul className="social-links">
            <li className="social-item">
              <Instagram className="icon" />
            </li>
            <li className="social-item">
              <FaTiktok className="icon" />
            </li>
          </ul>
        </div>
      </div>

      <div className="footer-bottom">
        <p>© Yatehevisto 2024 | Todos los derechos reservados</p>
        <ul className="footer-policies">
          <li onClick={() => handleNavigation('/terminos')}>Términos y condiciones |</li>
          <li onClick={() => handleNavigation('/envios')}>Envíos</li>
        </ul>
      </div>
      <ul className="footer-policies">
          <li onClick={() => handleNavigation('https://www.linkedin.com/in/francis-alonso/')}>Diseñado por: © Gus Design</li>
      </ul>
    </footer>
  );
};

export default Footer;
