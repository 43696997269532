import React, { useState, useRef, useEffect } from 'react';
import './LogoCarousel.css';

const LogoCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [logos, setLogos] = useState([]);
  const [error, setError] = useState(null); // Nuevo estado para manejar errores
  const carouselRef = useRef(null);
  const intervalRef = useRef(null);

  // Cargar las imágenes desde la base de datos
  useEffect(() => {
    fetch('https://gusitostore.cl/getGalleryCommunityImages.php')
      .then((response) => {
        if (!response.ok) {
          throw new Error('Error en la respuesta del servidor');
        }
        return response.json();
      })
      .then((data) => {
        if (data.error) {
          throw new Error(data.error);
        } else {
          setLogos(data);
        }
      })
      .catch((error) => {
        console.error('Error al cargar las imágenes:', error);
        setError(error.message); // Guardar el mensaje de error en el estado
      });
  }, []);

  // Mueve el carrusel automáticamente
  useEffect(() => {
    if (logos.length > 0) {
      intervalRef.current = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % logos.length);
      }, 2000); // Cambia la imagen cada 2 segundos
    }

    return () => clearInterval(intervalRef.current);
  }, [logos]);

  // Desplaza el carrusel cuando cambia el índice
  useEffect(() => {
    if (carouselRef.current && logos.length > 0) {
      const slideWidth = carouselRef.current.children[0]?.offsetWidth || 0;
      const scrollPosition = currentIndex * slideWidth;
      carouselRef.current.scrollTo({
        left: scrollPosition,
        behavior: 'smooth',
      });
    }
  }, [currentIndex, logos]);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % logos.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? logos.length - 1 : prevIndex - 1
    );
  };

  // Si ocurre un error, mostrar un mensaje claro
  if (error) {
    return <p>Error al cargar las imágenes: {error}</p>;
  }

  if (logos.length === 0) {
    return <p>Cargando imágenes...</p>;
  }

  return (
    <div className="logo-slider-container">
      <div className="slider-title-big">
        <h3 className="slider-title">COMUNIDAD // </h3>
        <h3 className="slider-title">YATEHEVISTO</h3>
      </div>
      <button className="slider-nav-button left" onClick={handlePrev}>‹</button>
      <div className="logo-slider-wrapper" ref={carouselRef}>
        {logos.map((logo, index) => (
          <div
            key={index}
            className={`logo-slide ${index === currentIndex ? 'active-slide' : ''}`}
            onClick={() => setCurrentIndex(index)}
          >
            <img
              src={logo.url}
              alt={`Logo ${index + 1}`}
              className="logo-slider-image"
            />
          </div>
        ))}
      </div>
      <button className="slider-nav-button right" onClick={handleNext}>›</button>
    </div>
  );
};

export default LogoCarousel;
