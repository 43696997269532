import React, { useState, useEffect } from 'react';
import './ImageUpload.css';

const ImageUpload = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState('');
  const [images, setImages] = useState({ desktop: [], mobile: [] });
  const [uploadTable, setUploadTable] = useState('portada'); // Tabla de destino ('portada' o 'portadamovil')

  // Obtener las imágenes actuales de las tablas
  useEffect(() => {
    const fetchImages = async () => {
      try {
        const desktopResponse = await fetch('https://gusitostore.cl/getPortadaImages.php');
        const mobileResponse = await fetch('https://gusitostore.cl/getPortadaMovilImages.php');
        const desktopData = await desktopResponse.json();
        const mobileData = await mobileResponse.json();

        setImages({
          desktop: desktopData || [],
          mobile: mobileData || [],
        });
      } catch (error) {
        console.error('Error al obtener las imágenes:', error);
      }
    };

    fetchImages();
  }, []);

  // Manejar el cambio de archivo
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  // Enviar el formulario y subir la imagen
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!selectedFile) {
      setUploadStatus('Por favor selecciona una imagen.');
      return;
    }

    const formData = new FormData();
    formData.append('image', selectedFile);
    formData.append('table', uploadTable); // Tabla de destino

    try {
      const response = await fetch('https://gusitostore.cl/subirPortada.php', {
        method: 'POST',
        body: formData,
      });

      const result = await response.json();
      if (result.success) {
        setUploadStatus('Imagen subida correctamente.');
        setImages((prevImages) => ({
          ...prevImages,
          [uploadTable === 'portada' ? 'desktop' : 'mobile']: [...prevImages[uploadTable === 'portada' ? 'desktop' : 'mobile'], { id: result.id, url: result.newImageUrl }]
        }));
      } else {
        setUploadStatus(`Error: ${result.message}`);
      }
    } catch (error) {
      console.error('Error al subir la imagen:', error);
      setUploadStatus('Error al subir la imagen.');
    }

    setSelectedFile(null);
  };

  // Manejar la eliminación de una imagen
  const handleDeleteImage = async (index, type) => {
    const table = type === 'desktop' ? 'portada' : 'portadamovil';
    const imageId = images[type][index].id; // Extraer el ID de la imagen

    try {
      const response = await fetch('https://gusitostore.cl/subirPortada.php', {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id: imageId, table }),
      });

      const result = await response.json();
      if (result.success) {
        setUploadStatus('Imagen eliminada correctamente.');
        setImages((prevImages) => ({
          ...prevImages,
          [type]: prevImages[type].filter((_, i) => i !== index),
        }));
      } else {
        setUploadStatus(`Error: ${result.message}`);
      }
    } catch (error) {
      console.error('Error al eliminar la imagen:', error);
      setUploadStatus('Error al eliminar la imagen.');
    }
  };

  return (
    <div className="image-upload-manager-container">
      <h2>Gestionar Portadas</h2>

      {/* Selector de tabla */}
      <div className="image-upload-table-selector">
        <label>
          Selecciona tipo de portada:
          <select onChange={(e) => setUploadTable(e.target.value)}>
            <option value="portada">Portada Escritorio</option>
            <option value="portadamovil">Portada Móvil</option>
          </select>
        </label>
      </div>

      {/* Imágenes actuales para escritorio y móvil */}
      {['desktop', 'mobile'].map((type) => (
        <div className="image-upload-manager-section" key={type}>
          <h3>{type === 'desktop' ? 'Portada Escritorio' : 'Portada Móvil'}</h3>
          <div className="image-upload-manager-image-list">
            {images[type].map((image, index) => (
              <div className="image-upload-manager-image-container" key={image.id}>
                <img src={image.url} alt={`Portada ${type}`} />
                <button onClick={() => handleDeleteImage(index, type)}>Eliminar</button>
              </div>
            ))}
          </div>
          {images[type].length < 5 && (
            <form onSubmit={handleSubmit}>
              <input type="file" onChange={handleFileChange} />
              <button type="submit">Subir Imagen</button>
            </form>
          )}
        </div>
      ))}

      {/* Mostrar mensaje de estado de la subida */}
      <p className={`image-upload-manager-upload-status ${uploadStatus.includes('Error') ? 'error' : 'success'}`}>{uploadStatus}</p>
    </div>
  );
};

export default ImageUpload;
