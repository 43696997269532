import React from 'react';
import { useNavigate } from 'react-router-dom';
import './ModifyCover.css'; // Asegúrate de tener los estilos necesarios

const ModifyCover = () => {
  const navigate = useNavigate();

  const goToGallery4Page = () => {
    navigate('/modify-gallery-4'); // Redirigir a la página de modificación de galería 4
  };

  const goToGallery6Page = () => {
    navigate('/modify-gallery-6'); // Redirigir a la página de modificación de galería 6
  };

  const goToComunidadGalleryPage = () => {
    navigate('/manage-gallery-comunidad'); // Redirigir a la página de gestión de galeriacomunidad
  };

  const goToUploadImagePage = () => {
    navigate('/upload-image'); // Redirigir a la página de carga de imagen
  };

  const goToUploadImageModule = () => {
    navigate('/image-upload-module'); // Redirigir a la página de carga de imagen
  };

  return (
    <div className="modify-cover-container">
      <h2>Modificar Portada</h2>
      <div className="button-group">
        <button onClick={goToGallery4Page} className="modify-gallery-button">
          Modificar Galería 4
        </button>

        <button onClick={goToGallery6Page} className="modify-gallery-button">
          Modificar Galería 6
        </button>

        <button onClick={goToComunidadGalleryPage} className="modify-gallery-button">
          Modificar Galería Comunidad
        </button>

        {/* Nuevo botón para redirigir a la página de subir imagen */}
        <button onClick={goToUploadImagePage} className="modify-gallery-button">
          Subir Imagen de Portada
        </button>

        <button onClick={goToUploadImageModule} className="modify-gallery-button">
          Modificar imagenes del split
        </button>
      </div>
    </div>
  );
};

export default ModifyCover;
