import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './AllProductsGallery.css';

const AllProductsGallery = () => {
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1); // Estado para la página actual
  const itemsPerPage = 12; // Máximo de productos por página
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch para obtener todos los productos desde el nuevo archivo PHP
    fetch(`https://gusitostore.cl/getAllProduct.php`)
      .then(response => response.json())
      .then(data => {
        if (data.message) {
          console.error(data.message);
        } else {
          setProducts(data);
        }
      })
      .catch(error => console.error('Error fetching products:', error));
  }, []);

  // Función para manejar la redirección a la página del producto
  const handleProductClick = (id) => {
    navigate(`/product/${id}`);
  };

  // Obtener los productos que se mostrarán en la página actual
  const indexOfLastProduct = currentPage * itemsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - itemsPerPage;
  const currentProducts = products.slice(indexOfFirstProduct, indexOfLastProduct);

  // Calcular el número total de páginas
  const totalPages = Math.ceil(products.length / itemsPerPage);

  // Manejar el cambio de página
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div>
      <div className="products-gallery-container">
        {currentProducts.map((product, index) => (
          <HoverableProductCard
            key={index}
            product={product}
            onClick={() => handleProductClick(product.id)}
          />
        ))}
      </div>

      {/* Contenedor de la paginación */}
      <div className="pagination-container">
        {[...Array(totalPages)].map((_, index) => (
          <button
            key={index}
            className={`pagination-button ${currentPage === index + 1 ? 'active-page' : ''}`}
            onClick={() => handlePageChange(index + 1)}
          >
            {index + 1}
          </button>
        ))}
      </div>
    </div>
  );
};

// Componente para manejar el hover de cada producto
const HoverableProductCard = ({ product, onClick }) => {
  const [currentImage, setCurrentImage] = useState(product.imagen);

  const handleMouseEnter = () => {
    if (product.imagen2) {
      setCurrentImage(product.imagen2); // Cambia a imagen2 solo si está disponible
    }
  };

  const handleMouseLeave = () => {
    setCurrentImage(product.imagen); // Vuelve a la imagen principal al quitar hover
  };

  return (
    <div className="product-card-container" onClick={onClick}>
      <img
        src={currentImage}
        alt={product.nombre}
        className="product-image-main"
        onMouseEnter={product.imagen2 ? handleMouseEnter : null} // Solo activa hover si hay imagen2
        onMouseLeave={product.imagen2 ? handleMouseLeave : null} // Solo activa hover si hay imagen2
      />
      <div className="product-info-container">
        <h3 className="product-title">{product.nombre}</h3>
        <p className="product-price">
          {parseInt(product.precio).toLocaleString('es-CL', {
            style: 'currency',
            currency: 'CLP',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          })}
        </p>
      </div>
    </div>
  );
};

export default AllProductsGallery;
