import React, { useState, useEffect } from "react";
import axios from "axios";
import './ManageGalleryComunidad.css';

const ManageGalleryComunidad = () => {
  const [images, setImages] = useState([]);
  const [newImage, setNewImage] = useState(null);
  const [imageName, setImageName] = useState("");
  const [loading, setLoading] = useState(true);
  const [uploading, setUploading] = useState(false); // Estado para manejar el estado de carga de la imagen
  const [error, setError] = useState(null);

  // Cargar imágenes desde la base de datos
  useEffect(() => {
    fetchImages();
  }, []);

  const fetchImages = async () => {
    try {
      setLoading(true);
      const response = await axios.get("https://gusitostore.cl/getImagesComunidad.php");
      setImages(response.data);
    } catch (err) {
      setError("Hubo un problema al cargar las imágenes.");
    } finally {
      setLoading(false);
    }
  };

  // Maneja el archivo seleccionado
  const handleImageUpload = (event) => {
    setNewImage(event.target.files[0]);
  };

  // Validar y subir imagen
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!newImage || !imageName) {
      alert("Por favor selecciona una imagen y proporciona un nombre.");
      return;
    }

    const formData = new FormData();
    formData.append("image", newImage);
    formData.append("name", imageName);

    try {
      setUploading(true); // Estado de carga
      const response = await axios.post(
        "https://gusitostore.cl/uploadImageComunidad.php",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.success) {
        alert("Imagen subida exitosamente");
        setImageName(""); // Limpiar el campo de nombre
        setNewImage(null); // Limpiar el input de archivo
        fetchImages(); // Volver a cargar las imágenes
      } else {
        alert(response.data.error || "Error al subir la imagen.");
      }
    } catch (err) {
      alert("Error al subir la imagen. Por favor, inténtalo de nuevo.");
    } finally {
      setUploading(false); // Desactivar estado de carga
    }
  };

  // Eliminar imagen
  const handleDelete = async (id) => {
    if (window.confirm("¿Estás seguro de que deseas eliminar esta imagen?")) {
      try {
        const response = await axios.post("https://gusitostore.cl/deleteImageComunidad.php", { id });
        if (response.data.success) {
          alert("Imagen eliminada correctamente.");
          fetchImages();
        } else {
          alert("Error al eliminar la imagen.");
        }
      } catch (err) {
        alert("Hubo un error al intentar eliminar la imagen.");
      }
    }
  };

  if (loading) {
    return <p>Cargando imágenes...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className="manage-gallery-container">
      <h2>Gestión de Galería Comunidad</h2>

      {/* Formulario de subida de imagen */}
      <form onSubmit={handleSubmit} className="upload-form">
        <input
          type="file"
          onChange={handleImageUpload}
          accept="image/*"
          disabled={uploading} // Deshabilitar mientras se sube una imagen
        />
        <input
          type="text"
          placeholder="Nombre de la imagen"
          value={imageName}
          onChange={(e) => setImageName(e.target.value)}
          disabled={uploading} // Deshabilitar mientras se sube una imagen
        />
        <button type="submit" disabled={uploading}>
          {uploading ? "Subiendo..." : "Subir Imagen"}
        </button>
      </form>

      {/* Lista de imágenes ya subidas */}
      <div className="image-list">
        {images.map((image) => (
          <div key={image.id} className="image-item">
            <img src={image.url} alt={image.nombre} className="gallery-image" />
            <p>{image.nombre}</p>
            <button onClick={() => handleDelete(image.id)} disabled={uploading}>
              Eliminar
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ManageGalleryComunidad;
