import React, { useState } from 'react';
import './Medidas.css';
import medida1 from '../img/medida1.png'; // Imagen principal
import galeriaImg1 from '../img/galeria1.jpg';
import galeriaImg2 from '../img/galeria2.jpg';
import galeriaImg3 from '../img/galeria3.jpg';
import galeriaImg4 from '../img/galeria4.jpg';
import galeriaImg5 from '../img/galeria5.jpg';

const galleryImages = [
  galeriaImg1, galeriaImg2, galeriaImg3, galeriaImg4, galeriaImg5,
];

const Medidas = () => {
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const closeImage = () => {
    setSelectedImage(null);
  };

  return (
    <div className="medidas-container">
      <h1 className="medidas-title">TABLAS DE MEDIDAS</h1>

      <div className="medidas-split-screen">
        {/* Lado izquierdo: imagen principal, título y descripción */}
        <div className="left-section">
          <img src={medida1} alt="Tabla de Medidas" className="medidas-main-image" />
          <div className="medidas-description">
            <h2>MEDIDAS REALES</h2>
            <p>
              Nuestros moldes están hechos en base a cuerpos reales, siempre pensando en la comodidad
              de las prendas. No dudes en contactarnos para realizar prendas a medida si es necesario.
            </p>
          </div>
        </div>

        {/* Lado derecho: galería */}
        <div className="right-section">
          <div className="gallery-grid">
            {galleryImages.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`Galería ${index + 1}`}
                className="gallery-image"
                onClick={() => handleImageClick(image)}
              />
            ))}
          </div>
        </div>
      </div>

      {/* Modal para ver la imagen en grande */}
      {selectedImage && (
        <div className="image-modal" onClick={closeImage}>
          <img src={selectedImage} alt="Imagen grande" className="image-modal-content" />
        </div>
      )}
    </div>
  );
};

export default Medidas;
