import React, { useContext, useState, useEffect } from 'react';
import { CartContext } from '../context/CartContext';
import './PaymentConfirmation.css';

const PaymentConfirmation = () => {
  const { cartItems, clearCart } = useContext(CartContext); // Obtener productos del carrito y la función para vaciar el carrito
  const [transactionDate, setTransactionDate] = useState('');
  const [shippingCost, setShippingCost] = useState(0); // Coste de envío obtenido desde el contexto o seleccionable

  // Calcular el subtotal del carrito
  const subtotal = cartItems.reduce((total, item) => total + item.precio * item.quantity, 0);

  // Calcular el total final (incluye el coste de envío)
  const totalFinal = subtotal + shippingCost;

  // Calcular la cantidad total de productos
  const totalItems = cartItems.reduce((total, item) => total + item.quantity, 0);

  // Obtener IDs de los productos en formato JSON
  const productIds = cartItems.map(item => item.id);

  // Al cargar el componente, obtener la fecha de la transacción y registrar la venta
  useEffect(() => {
    // Solo registrar la venta si el carrito tiene productos
    if (cartItems.length > 0) {
      const currentDate = new Date().toLocaleDateString('es-CL', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      });
      setTransactionDate(currentDate);

      const registerSale = async () => {
        const saleData = {
          productIds, // IDs de los productos
          totalCompra: totalFinal, // Total de la compra (incluyendo envío)
          cantidadTotal: totalItems, // Cantidad total de productos
          fechaPago: new Date().toISOString(), // Fecha en formato ISO
          costoEnvio: shippingCost, // Incluir el costo de envío en la venta
        };

        try {
          const response = await fetch('https://gusitostore.cl/registerSale.php', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(saleData),
          });

          const data = await response.json();
          if (data.success) {
            console.log('Venta registrada exitosamente.');
            clearCart(); // Vaciar el carrito después de la compra
          } else {
            console.error('Error al registrar la venta:', data.message);
          }
        } catch (error) {
          console.error('Error al conectar con el servidor:', error);
        }
      };

      registerSale(); // Llamar a la función para registrar la venta
    }
  }, [cartItems, productIds, totalFinal, totalItems, clearCart, shippingCost]);

  return (
    <div className="payment-confirmation-container">
      <h2>¡Gracias por tu compra!</h2>
      <p>Tu pago ha sido procesado con éxito el {transactionDate}. A continuación, te mostramos el detalle de los productos adquiridos:</p>

      <div className="purchased-items">
        {cartItems.length > 0 ? (
          cartItems.map((item) => (
            <div key={item.id} className="purchased-item">
              <p><strong>Producto:</strong> {item.nombre}</p>
              <p><strong>Cantidad:</strong> {item.quantity}</p>
              <p><strong>Precio unitario:</strong> ${item.precio.toLocaleString('es-CL')}</p>
              <p><strong>Precio total:</strong> ${(item.precio * item.quantity).toLocaleString('es-CL')}</p>
            </div>
          ))
        ) : (
          <p>No hay productos en el carrito.</p>
        )}
      </div>

      {/* Resumen de la compra */}
      <div className="order-summary">
        <h3>Resumen del pedido:</h3>
        <p><strong>Subtotal:</strong> ${subtotal.toLocaleString('es-CL')}</p>
        <p><strong>Envío:</strong> {shippingCost > 0 ? `$${shippingCost.toLocaleString('es-CL')}` : 'Gratis'}</p>
        <p><strong>Total:</strong> ${totalFinal.toLocaleString('es-CL')}</p>
      </div>

      <div className="payment-status">
        <h3>Estado del pago: <span className="status-paid">Pagado</span></h3>
      </div>

      {/* Mensaje de agradecimiento */}
      <div className="thank-you-message">
        <p>Tu pedido será procesado y enviado pronto. Si tienes alguna pregunta o inquietud, no dudes en contactarnos.</p>
        <p>¡Gracias por comprar en nuestra tienda!</p>
      </div>
    </div>
  );
};

export default PaymentConfirmation;
