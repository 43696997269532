import React, { useState, useEffect } from 'react';
import './Inventory.css';
import { FaTrash } from 'react-icons/fa';

const Inventory = () => {
  const [inventory, setInventory] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [expandedCategories, setExpandedCategories] = useState([]);

  const fetchInventory = async () => {
    try {
      const response = await fetch('https://gusitostore.cl/fetchFullProducts.php');
      const data = await response.json();
      setInventory(data);
      setLoading(false);
    } catch (error) {
      console.error('Error al obtener el inventario:', error);
      setLoading(false);
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await fetch('https://gusitostore.cl/getCategories.php');
      const data = await response.json();
      setCategories(data);
    } catch (error) {
      console.error('Error al obtener las categorías:', error);
    }
  };

  useEffect(() => {
    fetchInventory();
    fetchCategories();
  }, []);

  const groupByCategory = () => {
    return categories.map((category) => ({
      ...category,
      items: inventory.filter((item) => item.tipo === category.id),
    }));
  };

  const groupedInventory = groupByCategory();

  const handleCategoryClick = (categoryId) => {
    if (expandedCategories.includes(categoryId)) {
      setExpandedCategories(expandedCategories.filter((id) => id !== categoryId));
    } else {
      setExpandedCategories([...expandedCategories, categoryId]);
    }
  };

  const handleDelete = async (productId) => {
    if (!productId) return;

    if (window.confirm("¿Estás seguro de que deseas eliminar este producto?")) {
      try {
        const response = await fetch(`https://gusitostore.cl/deleteProduct.php`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ id: productId }),
        });

        const data = await response.json();
        if (data.success) {
          setInventory(inventory.filter((item) => item.id !== productId));
          alert("Producto eliminado correctamente.");
        } else {
          alert("Error al eliminar el producto: " + data.message);
        }
      } catch (error) {
        alert("Hubo un error al intentar eliminar el producto.");
      }
    }
  };

  return (
    <div className="inventory-container-custom">
      <h2>Inventario de Productos</h2>
      {loading ? (
        <p>Cargando inventario...</p>
      ) : (
        <div>
          {groupedInventory.map((category, index) => (
            <div key={category.id} className={`category-section-custom cat-${index + 1}`}>
              <h3 onClick={() => handleCategoryClick(category.id)} className="category-title-custom">
                {category.nombre} ({category.items.length})
              </h3>

              {/* Mostrar los productos en estilo tarjeta si la categoría está expandida */}
              {expandedCategories.includes(category.id) && (
                <div className="product-card-container">
                  {category.items.length > 0 ? (
                    category.items.map((item) => (
                      <div key={item.id} className="product-card">
                        <div className="product-details">
                          <p className="product-id">ID: {item.id}</p>
                          <img src={item.imagen} alt={item.nombre} />
                          <p className="product-name">{item.nombre}</p>
                          <p className="product-price">${item.precio.toLocaleString('es-CL')}</p>
                          <p className="product-description">{item.descripcion}</p>
                          <p className="product-details-text">{item.detalles}</p>
                          <button
                            onClick={() => handleDelete(item.id)}
                            className="delete-button"
                          >
                            <FaTrash /> Eliminar
                          </button>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p>No hay productos en esta categoría.</p>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Inventory;
