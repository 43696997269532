// ProductoTallas.js
import React, { useEffect, useState } from 'react';
import './ProductoTallas.css'; // Asegúrate de crear este archivo CSS

const ProductoTallas = () => {
  const [productos, setProductos] = useState([]); // Lista de todos los productos
  const [productoSeleccionado, setProductoSeleccionado] = useState(null); // Producto seleccionado
  const [tallas, setTallas] = useState([]); // Tallas asociadas al producto
  const [disponibles, setDisponibles] = useState([]); // Tallas disponibles para agregar
  const [nuevaTalla, setNuevaTalla] = useState(''); // Nueva talla a agregar
  const [nuevoStock, setNuevoStock] = useState(''); // Stock para la nueva talla
  const [mensajeExito, setMensajeExito] = useState(''); // Mensaje de éxito
  const [mensajeError, setMensajeError] = useState(''); // Mensaje de error
  const [totalStock, setTotalStock] = useState(0); // Total de stock

  // Obtener todos los productos
  useEffect(() => {
    fetch('https://gusitostore.cl/getProductos.php')
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data)) {
          setProductos(data);
        } else {
          console.error('Datos de productos inválidos:', data);
        }
      })
      .catch((error) => console.error('Error al obtener productos:', error));
  }, []);

  // Obtener las tallas del producto seleccionado
  useEffect(() => {
    if (productoSeleccionado) {
      fetch(`https://gusitostore.cl/getTallasProducto.php?producto_id=${productoSeleccionado}`)
        .then((response) => response.json())
        .then((data) => {
          if (data.tallasProducto && data.tallasDisponibles) {
            setTallas(data.tallasProducto);
            setDisponibles(data.tallasDisponibles);
          } else {
            console.error('Datos de tallas inválidos:', data);
            setTallas([]);
            setDisponibles([]);
          }
        })
        .catch((error) => console.error('Error al obtener tallas:', error));
    } else {
      setTallas([]);
      setDisponibles([]);
    }
  }, [productoSeleccionado]);

  // Calcular el total de stock cada vez que cambian las tallas
  useEffect(() => {
    const total = tallas.reduce((acc, talla) => acc + (parseInt(talla.stock) || 0), 0);
    setTotalStock(total);
  }, [tallas]);

  // Manejar selección de producto
  const handleProductoSeleccionado = (e) => {
    setProductoSeleccionado(e.target.value); // Establecer el producto seleccionado
    setMensajeExito('');
    setMensajeError('');
  };

  // Manejar eliminación de talla
  const eliminarTalla = (tallaId) => {
    fetch(`https://gusitostore.cl/eliminarTallaProducto.php`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ producto_id: productoSeleccionado, talla_id: tallaId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setTallas(tallas.filter((talla) => talla.id !== tallaId));
          setMensajeExito(data.message || 'Talla eliminada con éxito');
          setMensajeError('');
        } else {
          setMensajeError(data.error || 'Error al eliminar la talla');
          setMensajeExito('');
        }
      })
      .catch((error) => {
        console.error('Error al eliminar talla:', error);
        setMensajeError('Error al eliminar talla');
        setMensajeExito('');
      });
  };

  // Manejar adición de talla
  const agregarTalla = () => {
    if (nuevaTalla && nuevoStock) {
      const tallaSeleccionada = disponibles.find((t) => t.id === parseInt(nuevaTalla));

      if (!tallaSeleccionada) {
        console.error('Error: Talla no encontrada.');
        setMensajeError('Talla no encontrada.');
        return;
      }

      fetch(`https://gusitostore.cl/agregarTallaProducto.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ producto_id: productoSeleccionado, talla_id: nuevaTalla, stock: nuevoStock }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            setTallas([
              ...tallas,
              { id: nuevaTalla, talla: tallaSeleccionada.talla, stock: nuevoStock },
            ]);
            setNuevaTalla('');
            setNuevoStock('');
            setMensajeExito(`Talla "${tallaSeleccionada.talla}" añadida con stock ${nuevoStock} correctamente.`);
            setMensajeError('');
          } else {
            console.error('Error al agregar talla:', data.error);
            setMensajeError(data.error || 'Error al agregar talla.');
            setMensajeExito('');
          }
        })
        .catch((error) => {
          console.error('Error al agregar talla:', error);
          setMensajeError('Error al agregar talla. Verifica la conexión y los datos.');
          setMensajeExito('');
        });
    } else {
      setMensajeError('Por favor, selecciona una talla y especifica el stock.');
      setMensajeExito('');
    }
  };

  // Manejar actualización del stock de una talla existente
  const actualizarStock = (tallaId, stock) => {
    if (stock === '') {
      setMensajeError('El stock no puede estar vacío.');
      setMensajeExito('');
      return;
    }

    fetch(`https://gusitostore.cl/actualizarStockProducto.php`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ producto_id: productoSeleccionado, talla_id: tallaId, stock: parseInt(stock) }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          const nuevasTallas = tallas.map((talla) =>
            talla.id === tallaId ? { ...talla, stock: stock } : talla
          );
          setTallas(nuevasTallas);
          setMensajeExito(data.message || 'Stock actualizado con éxito.');
          setMensajeError('');
        } else {
          setMensajeError(data.error || 'Error al actualizar stock.');
          setMensajeExito('');
        }
      })
      .catch((error) => {
        console.error('Error al actualizar stock:', error);
        setMensajeError('Error al actualizar stock. Verifica la conexión.');
        setMensajeExito('');
      });
  };

  return (
    <div className="gestion-tallas-container">
      <h2>Gestión de Tallas</h2>

      {/* Seleccionar un producto */}
      <div className="selector-producto">
        <label htmlFor="producto-select">Seleccionar Producto:</label>
        <select id="producto-select" onChange={handleProductoSeleccionado} value={productoSeleccionado || ''}>
          <option value="">Seleccionar producto</option>
          {productos.map((producto) => (
            <option key={producto.id} value={producto.id}>
              {producto.nombre}
            </option>
          ))}
        </select>
      </div>

      {/* Mostrar las tallas del producto */}
      {productoSeleccionado && (
        <div className="gestion-tallas">
          <h3>Tallas del Producto</h3>
          {tallas.length > 0 ? (
            <ul className="lista-tallas">
              {tallas.map((talla) => (
                <li key={talla.id} className="talla-item">
                  <span className="talla-nombre">{talla.talla}</span>
                  <span className="talla-stock">
                    Stock:
                    <input
                      type="number"
                      value={talla.stock}
                      onChange={(e) => actualizarStock(talla.id, e.target.value)}
                      min="0"
                    />
                  </span>
                  <button className="eliminar-button" onClick={() => eliminarTalla(talla.id)}>
                    Eliminar
                  </button>
                </li>
              ))}
            </ul>
          ) : (
            <p className="sin-tallas">Este producto no tiene tallas asociadas.</p>
          )}

          {/* Agregar nueva talla */}
          <div className="agregar-talla">
            <h4>Agregar Talla</h4>
            <div className="form-agregar-talla">
              <select value={nuevaTalla} onChange={(e) => setNuevaTalla(e.target.value)}>
                <option value="">Seleccionar Talla</option>
                {disponibles.map((talla) => (
                  <option key={talla.id} value={talla.id}>
                    {talla.talla}
                  </option>
                ))}
              </select>
              <input
                type="number"
                placeholder="Stock"
                value={nuevoStock}
                onChange={(e) => setNuevoStock(e.target.value)}
                min="0"
              />
              <button className="agregar-button" onClick={agregarTalla}>
                Agregar
              </button>
            </div>
          </div>

          {/* Mensajes de éxito y error */}
          {mensajeExito && <p className="mensaje-exito">{mensajeExito}</p>}
          {mensajeError && <p className="mensaje-error">{mensajeError}</p>}

          {/* Total de stock */}
          <div className="total-stock">
            <h3>Total Stock: {totalStock}</h3>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductoTallas;
