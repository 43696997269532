import React from 'react';
import DesktopPortada from './DesktopPortada';
import MobilePortada from './MobilePortada';
import WelcomeMessage from './WelcomeMessage';
import ImageGallery from './ImageGallery';
import ImageGallerySix from './ImageGallerySix';
import SplitScreenCard from './SplitScreenCard';
import LogoCarousel from './LogoCarousel';
import ShopBanner from './ShopBanner';
import './Home.css';

const Home = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      {/* Componentes de portada */}
      <DesktopPortada />
      <MobilePortada />
      
      <ShopBanner />
      <WelcomeMessage />
      <ImageGallery />
      <SplitScreenCard />
      <ImageGallerySix />
      <LogoCarousel />
    </div>
  );
};

export default Home;
