import React, { useState } from 'react';
import { Drawer, List, ListItem, ListItemText, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import './HamburgerMenu.css';

const HamburgerMenu = () => {
  const [open, setOpen] = useState(false); // Estado para controlar la apertura del menú
  const [menuState, setMenuState] = useState('main'); // Estado del menú: 'main' para categorías principales, 'subcategories' para subcategorías
  const navigate = useNavigate();

  const toggleDrawer = (open) => (event) => {
    if (event?.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setOpen(open);
  };

  const handleCategoryClick = () => {
    setMenuState('subcategories'); // Cambia el estado para mostrar las subcategorías
  };

  const handleNavigate = (path) => {
    navigate(path);
    setOpen(false); // Cierra el menú después de la navegación
  };

  const handleBackToMain = () => {
    setMenuState('main'); // Vuelve al menú principal
  };

  // Menú principal
  const mainMenu = (
    <List className="drawer-list">
      <ListItem button onClick={() => handleNavigate('/')}>
        <ListItemText primary="HOME" />
      </ListItem>
      <ListItem button onClick={handleCategoryClick}>
        <ListItemText primary="SHOP" />
      </ListItem>
      <ListItem button onClick={() => handleNavigate('/nosotras')}>
        <ListItemText primary="NOSOTRAS" />
      </ListItem>
      <ListItem button onClick={() => handleNavigate('/tienda')}>
        <ListItemText primary="TIENDA" />
      </ListItem>
      <ListItem button onClick={() => handleNavigate('/medidas')}>
        <ListItemText primary="MEDIDAS" />
      </ListItem>
    </List>
  );

  // Subcategorías
  const subcategoriesMenu = (
    <List className="drawer-list">
      <ListItem button onClick={handleBackToMain}>
        <ArrowBackIcon />
        <ListItemText primary="Volver al menú principal" />
      </ListItem>
      <ListItem button onClick={() => handleNavigate('/shop/accesorios')}>
        <ListItemText primary="ACCESORIOS" />
      </ListItem>
      <ListItem button onClick={() => handleNavigate('/shop/chaquetas-pol')}>
        <ListItemText primary="CHAQUETAS & POLERONES" />
      </ListItem>
      <ListItem button onClick={() => handleNavigate('/shop/faldas-shorts')}>
        <ListItemText primary="FALDAS & SHORTS" />
      </ListItem>
      <ListItem button onClick={() => handleNavigate('/shop/pantalones')}>
        <ListItemText primary="PANTALONES" />
      </ListItem>
      <ListItem button onClick={() => handleNavigate('/shop/tops')}>
        <ListItemText primary="TOPS" />
      </ListItem>
      <ListItem button onClick={() => handleNavigate('/shop/verano-forever')}>
        <ListItemText primary="VERANO FOREVER ❤" />
      </ListItem>
    </List>
  );

  return (
    <div className="hamburger-menu" style={{ position: 'relative' }}>
      {/* Botón para abrir el menú */}
      <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
        <MenuIcon style={{ fontSize: '30px', color: '#000' }} />
      </IconButton>
      <Drawer
        anchor="left"
        open={open}
        onClose={toggleDrawer(false)}
        transitionDuration={500}
      >
        <div className="drawer-header" style={{ display: 'flex', justifyContent: 'flex-end', padding: '10px' }}>
          <IconButton edge="start" color="inherit" aria-label="close" onClick={toggleDrawer(false)}>
            <CloseIcon style={{ fontSize: '30px', color: '#000' }} />
          </IconButton>
        </div>
        {/* Renderiza el menú basado en el estado actual */}
        {menuState === 'main' ? mainMenu : subcategoriesMenu}
      </Drawer>
    </div>
  );
};

export default HamburgerMenu;
