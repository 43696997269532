import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './SplitScreenEditor.css';

const SplitScreenEditor = () => {
  const [imageUrl, setImageUrl] = useState(''); // Para la imagen de la izquierda
  const [newImage, setNewImage] = useState(null); // Imagen que el usuario sube
  const [productIds, setProductIds] = useState(['', '', '', '']); // IDs de los productos de la derecha
  const [allProducts, setAllProducts] = useState([]); // Todos los productos disponibles
  const [items, setItems] = useState([]); // Productos actuales de la mitad derecha

  useEffect(() => {
    // Obtener la imagen de la mitad izquierda desde la base de datos
    fetch('https://gusitostore.cl/getSplitImage.php')
      .then((response) => response.json())
      .then((data) => setImageUrl(data.url))
      .catch((error) => console.error('Error al cargar la imagen:', error));

    // Obtener los productos actuales para la mitad derecha
    fetch('https://gusitostore.cl/getSplitData.php')
      .then((response) => response.json())
      .then((data) => setItems(data))
      .catch((error) => console.error('Error al cargar los productos:', error));

    // Obtener la lista de todos los productos para la lista desplegable
    fetch('https://gusitostore.cl/getAllProducts.php')
      .then((response) => response.json())
      .then((data) => setAllProducts(data))
      .catch((error) => console.error('Error al cargar los productos:', error));
  }, []);

  // Función para manejar la subida de una nueva imagen
  const handleImageUpload = (e) => {
    setNewImage(e.target.files[0]);
  };

  // Guardar cambios para la mitad izquierda
  const handleSaveLeft = async () => {
    const formData = new FormData();
    formData.append('image', newImage);

    try {
      const response = await axios.post('https://gusitostore.cl/uploadSplitImage.php', formData);
      if (response.data.success) {
        alert('Imagen guardada con éxito');
        setImageUrl(response.data.url); // Actualizar la URL de la imagen
      } else {
        alert('Error al guardar la imagen');
      }
    } catch (error) {
      console.error('Error al guardar la imagen:', error);
      alert('Error al subir la imagen.');
    }
  };

  // Guardar cambios para la mitad derecha (actualizar IDs de productos)
  const handleSaveRight = async () => {
    try {
      const response = await axios.post('https://gusitostore.cl/updateProductIds.php', {
        ids: productIds,
      });
      if (response.data.success) {
        alert('IDs de productos guardadas con éxito');
        setItems(response.data.updatedItems); // Actualizar los productos mostrados
      } else {
        alert('Error al guardar las IDs');
      }
    } catch (error) {
      console.error('Error al guardar las IDs:', error);
      alert('Error al guardar las IDs.');
    }
  };

  // Cambiar el producto seleccionado para una ID específica
  const handleProductChange = (index, productId) => {
    const updatedIds = [...productIds];
    updatedIds[index] = productId;
    setProductIds(updatedIds);
  };

  return (
    <div className="split-screen-editor-container">
      {/* Parte izquierda: Edición de la imagen */}
      <div className="split-screen-editor-left">
        <h2>Editar Imagen de la Izquierda</h2>
        <img src={imageUrl} alt="Imagen Actual" className="left-image-preview" />
        <input type="file" onChange={handleImageUpload} />
        <button onClick={handleSaveLeft}>Guardar Cambios (Izquierda)</button>
      </div>

      {/* Parte derecha: Edición de los productos */}
      <div className="split-screen-editor-right">
        <h2>Editar Productos de la Derecha</h2>
        {productIds.map((id, index) => (
          <div key={index} className="product-selection-container">
            <label>ID del Producto {index + 1} (Actual: {id}):</label>
            <select
              value={id}
              onChange={(e) => handleProductChange(index, e.target.value)}
            >
              <option value="">Selecciona un producto</option>
              {allProducts.map((product) => (
                <option key={product.id} value={product.id}>
                  {product.nombre} - ID: {product.id} - Precio: ${product.precio}
                </option>
              ))}
            </select>
          </div>
        ))}
        <button onClick={handleSaveRight}>Guardar Cambios (Derecha)</button>
      </div>
    </div>
  );
};

export default SplitScreenEditor;
