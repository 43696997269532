// TallasPage.js
import React from 'react';
import ProductoTallas from './ProductoTallas';
import './TallasPage.css'; // Crea este archivo para estilos específicos de la página

const TallasPage = () => {
  return (
    <div className="tallas-page-container">
      <ProductoTallas />
    </div>
  );
};

export default TallasPage;
