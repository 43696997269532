import React, { useContext } from 'react';
import './CartSidebar.css';
import { CartContext } from '../context/CartContext';

const CartSidebar = ({ isOpen, toggleSidebar }) => {
  const { cartItems, removeFromCart, updateCartQuantity } = useContext(CartContext);

  // Calcular el precio total de todos los productos
  const totalPrice = cartItems.reduce((total, item) => total + Number(item.precio) * item.quantity, 0);

  // Función para manejar cambios en la cantidad de productos
  const handleQuantityChange = (cartId, newQuantity) => {
    if (newQuantity > 0) {
      updateCartQuantity(cartId, newQuantity);
    }
  };

  const handleCheckout = () => {
    toggleSidebar(); // Cerrar el sidebar
    window.location.href = '/paymentmodule'; // Forzar la recarga completa de la página
  };

  return (
    <div className={`cart-sidebar ${isOpen ? 'open' : ''}`}>
      <div className="cart-sidebar-header">
        <h3>Tu Carrito ({cartItems.reduce((total, item) => total + item.quantity, 0)} {cartItems.length === 1 ? 'producto' : 'productos'})</h3>
        <button className="close-btn" onClick={toggleSidebar}>X</button>
      </div>

      {cartItems.length === 0 ? (
        <p className="empty-cart">Tu carrito está vacío</p>
      ) : (
        <div className="cart-sidebar-content">
          {cartItems.map((item) => (
            <div key={item.cartId} className="cart-sidebar-item">
              <img src={item.imagen} alt={item.nombre} className="cart-item-image" />
              <div className="cart-item-details">
                <p>{item.nombre}</p>
                <p className="talla-title">Talla: <span className="talla-seleccionada">{item.size}</span></p>
                <p>Precio: {Number(item.precio).toLocaleString('es-CL', { style: 'currency', currency: 'CLP' })}</p>
                <p>Precio total: {(Number(item.precio) * item.quantity).toLocaleString('es-CL', { style: 'currency', currency: 'CLP' })}</p>
                <div className="cart-item-quantity">
                  <button onClick={() => handleQuantityChange(item.cartId, item.quantity - 1)}>-</button>
                  <span>{item.quantity}</span>
                  <button onClick={() => handleQuantityChange(item.cartId, item.quantity + 1)}>+</button>
                </div>
              </div>
              <button className="cart-item-remove" onClick={() => removeFromCart(item.cartId)}>Eliminar</button>
            </div>
          ))}

          <div className="cart-sidebar-total">
            <p>Total del carrito: {totalPrice.toLocaleString('es-CL', { style: 'currency', currency: 'CLP' })}</p>
          </div>
          <button className="checkout-btn" onClick={handleCheckout}>Ir a pagar</button>
        </div>
      )}
    </div>
  );
};

export default CartSidebar;
