import React, { useState, useEffect } from 'react';

const ImageUploaderModule = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadSuccess, setUploadSuccess] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [products, setProducts] = useState([]); // Lista de productos
  const [splitIds, setSplitIds] = useState([]); // IDs actuales en splitid
  const [selectedProductId, setSelectedProductId] = useState(''); // Producto seleccionado

  useEffect(() => {
    // Obtener productos de la base de datos
    fetch('https://gusitostore.cl/getAllProducts.php')
      .then((response) => response.json())
      .then((data) => setProducts(data))
      .catch((error) => setErrorMessage('Error al cargar los productos.'));
    
    // Obtener los IDs actuales en la tabla splitid
    fetch('https://gusitostore.cl/getSplitIds.php')
      .then((response) => response.json())
      .then((data) => setSplitIds(data))
      .catch((error) => setErrorMessage('Error al cargar las IDs actuales.'));
  }, []);

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedFile) {
      setErrorMessage('Por favor selecciona una imagen.');
      return;
    }

    if (splitIds.length >= 8) {
      setErrorMessage('No puedes agregar más de 8 productos.');
      return;
    }

    const formData = new FormData();
    formData.append('image', selectedFile);
    formData.append('productId', selectedProductId); // Añadir el producto seleccionado

    try {
      const response = await fetch('https://gusitostore.cl/uploadImageAndUpdateSplit.php', {
        method: 'POST',
        body: formData,
      });

      const result = await response.json();
      if (result.success) {
        setUploadSuccess(result.url);
        setSplitIds([...splitIds, selectedProductId]); // Agregar la nueva ID a splitid
        setErrorMessage('');
      } else {
        setErrorMessage(result.message || 'Error al subir la imagen.');
      }
    } catch (error) {
      setErrorMessage('Hubo un error al conectar con el servidor.');
    }
  };

  return (
    <div className="image-uploader-module-container">
      <h2>Subir Imagen y Asignar Producto</h2>
      {uploadSuccess && (
        <div className="image-uploader-success-message">
          <p>Imagen subida con éxito. URL: {uploadSuccess}</p>
          <img src={uploadSuccess} alt="Imagen subida" />
        </div>
      )}
      {errorMessage && <p className="image-uploader-error-message">{errorMessage}</p>}
      <form onSubmit={handleSubmit} className="image-uploader-form">
        <label>Selecciona un Producto:</label>
        <select value={selectedProductId} onChange={(e) => setSelectedProductId(e.target.value)}>
          <option value="">Selecciona un producto</option>
          {products.map((product) => (
            <option key={product.id} value={product.id}>
              {product.nombre} - ID: {product.id}
            </option>
          ))}
        </select>

        <input type="file" accept="image/*" onChange={handleFileChange} />
        <button type="submit">Subir Imagen</button>
      </form>
    </div>
  );
};

export default ImageUploaderModule;
