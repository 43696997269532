import React, { useContext, useState } from 'react';
import { AppBar, Toolbar, IconButton, Button, Box } from '@mui/material';
import { ShoppingCart, AccountCircle, Search } from '@mui/icons-material';
import { UserContext } from '../context/UserContext';
import { CartContext } from '../context/CartContext'; 
import logo from '../img/logo0.png';
import HamburgerMenu from './HamburgerMenu';
import './Nav.css'; 
import CartSidebar from './CartSidebar'; // Importamos el nuevo componente CartSidebar

const Nav = () => {
  const { user } = useContext(UserContext); 
  const { cartItems } = useContext(CartContext); 
  const [menuOpen, setMenuOpen] = useState(false);
  const [isSidebarOpen, setSidebarOpen] = useState(false); // Estado para abrir/cerrar el sidebar
  let timeoutId;

  const handleMenuEnter = () => {
    clearTimeout(timeoutId);
    setMenuOpen(true);
  };

  const handleMenuLeave = () => {
    timeoutId = setTimeout(() => {
      setMenuOpen(false);
    }, 200);
  };

  const handleCategoryClick = (url) => {
    window.location.href = url; // Forzar la recarga completa de la página
    setMenuOpen(false);
  };

  // Función para abrir/cerrar el carrito lateral
  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  return (
    <>
      <AppBar position="sticky" sx={{ backgroundColor: '#ffffff' }} className="app-bar">
        <Toolbar className="toolbar">
          {/* Menú hamburguesa visible solo en móviles */}
          <Box className="hamburger-search">
            <IconButton title="Menu" className="icon hamburger">
              <HamburgerMenu />
            </IconButton>
            {/* Icono de lupa en móviles */}
            <IconButton title="Search" className="icon search-mobile">
              <Search />
            </IconButton>
          </Box>

          {/* Logo centrado */}
          <Box className="logo-container" onClick={() => (window.location.href = '/')}>
            <img src={logo} alt="Mi Tienda Logo" className="logo" />
          </Box>

          {/* Iconos de perfil, carrito y lupa en escritorio */}
          <Box className="profile-cart">
            {/* Icono de lupa en escritorio */}
            <IconButton title="Search" className="icon search-desktop">
              <Search />
            </IconButton>

            {/* Mostrar el icono de perfil si el usuario ha iniciado sesión */}
            {user && (
              <IconButton onClick={() => (window.location.href = '/profile')} title="Profile" className="icon">
                <AccountCircle />
              </IconButton>
            )}

            {/* Ícono de carrito con contador de productos */}
            <IconButton onClick={toggleSidebar} title="Cart" className="icon cart-icon">
              <ShoppingCart />
              {cartItems.length > 0 && (
                <span className="cart-count">{cartItems.length}</span> // Mostrar número de items
              )}
            </IconButton>
          </Box>
        </Toolbar>

        {/* Barra de navegación para pantallas grandes */}
        <Box className="nav-large">
          <Button className="nav-button" onClick={() => (window.location.href = '/')}>Home</Button>

          <Box onMouseEnter={handleMenuEnter} onMouseLeave={handleMenuLeave} className="dropdown">
            <Button className="nav-button"  onClick={() => (window.location.href = '/shop')}>Shop</Button>

            {menuOpen && (
              <Box className="dropdown-menu">
                <Button onClick={() => handleCategoryClick('/shop/accesorios')} className="dropdown-item">
                  Accesorios
                </Button>
                <Button onClick={() => handleCategoryClick('/shop/chaquetas-pol')} className="dropdown-item">
                  Chaquetas y Polerones
                </Button>
                <Button onClick={() => handleCategoryClick('/shop/faldas-shorts')} className="dropdown-item">
                  Faldas y Shorts
                </Button>
                <Button onClick={() => handleCategoryClick('/shop/pantalones')} className="dropdown-item">
                  Pantalones
                </Button>
                <Button onClick={() => handleCategoryClick('/shop/tops')} className="dropdown-item">
                  Tops
                </Button>
                <Button onClick={() => handleCategoryClick('/shop/verano-forever')} className="dropdown-item">
                  Verano Forever
                </Button>
              </Box>
            )}
          </Box>

          <Button className="nav-button" onClick={() => (window.location.href = '/nosotras')}>Nosotras</Button>
          <Button className="nav-button" onClick={() => (window.location.href = '/tienda')}>Tienda</Button>
          <Button className="nav-button" onClick={() => (window.location.href = '/medidas')}>Medidas</Button> {/* Nueva categoría Medidas */}
        </Box>
      </AppBar>

      {/* Componente del carrito que se abre desde el lado derecho */}
      <CartSidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
    </>
  );
};

export default Nav;
