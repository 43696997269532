import React from 'react';
import './TermsAndConditions.css'; // Agrega tu archivo CSS para estilizar el componente

const TermsAndConditions = () => {
  return (
    <div className="terms-container">
      <h1>Términos y Condiciones</h1>
      <p>Bienvenido a nuestra página de Términos y Condiciones. Al utilizar nuestro sitio web, aceptas los siguientes términos y condiciones:</p>

      <h2>1. Uso del sitio web</h2>
      <p>El contenido de este sitio web es solo para tu información y uso general. Está sujeto a cambios sin previo aviso.</p>

      <h2>2. Propiedad intelectual</h2>
      <p>Todo el contenido incluido en este sitio web, como texto, gráficos, logotipos, imágenes y compilaciones de datos, es propiedad nuestra o de nuestros licenciantes. Está protegido por las leyes de propiedad intelectual aplicables.</p>

      <h2>3. Enlaces a otros sitios web</h2>
      <p>Este sitio web puede contener enlaces a otros sitios de interés. Sin embargo, una vez que hayas usado estos enlaces para salir de nuestro sitio, no tenemos control sobre esos otros sitios web.</p>

      <h2>4. Limitación de responsabilidad</h2>
      <p>No seremos responsables de ninguna pérdida o daño que surja del uso de este sitio web.</p>

      <h2>5. Cambios a los términos y condiciones</h2>
      <p>Nos reservamos el derecho de modificar estos términos y condiciones en cualquier momento. Es tu responsabilidad revisar estos términos periódicamente para estar al tanto de cualquier cambio.</p>

      <p>Si tienes alguna pregunta sobre nuestros términos, no dudes en <a href="/contacto">contactarnos</a>.</p>
    </div>
  );
};

export default TermsAndConditions;
