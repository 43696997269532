import React, { useState, useEffect } from "react";
import "./ImageGallerySix.css";

const ImageGallerySix = () => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch("https://gusitostore.cl/getImagesFromGallerySix.php")
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setItems(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setError("Hubo un problema al cargar las imágenes.");
        setLoading(false);
      });
  }, []);

  if (loading) return <p>Cargando imágenes...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="image-gallery-container">
      <h2 className="gallery-title">NUESTROS INDISPENSABLES :)</h2>
      <div className="image-gallery-wrapper">
        {items.slice(0, 6).map((item, index) => (
          <div
            key={index}
            className="image-container-six"
            onClick={() => (window.location.href = `/product/${item.id}`)}
          >
            <img
              src={item.urlbase}
              alt={item.nombre}
              className="gallery-image-six"
            />
            <img
              src={item.urlhover}
              alt={`${item.nombre} Hover`}
              className="hover-image-six"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImageGallerySix;
