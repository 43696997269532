import React, { useContext, useState } from 'react';
import { CartContext } from '../context/CartContext'; // Importar el contexto del carrito
import './PaymentModule.css'; // Importar los estilos CSS

const PaymentModule = () => {
  const { cartItems, shippingCost, updateShippingCost } = useContext(CartContext); // Obtener productos del carrito, el costo de envío y la función para actualizar el costo de envío
  const [contactEmail, setContactEmail] = useState('');
  const [subscribe, setSubscribe] = useState(false);
  const [deliveryMethod, setDeliveryMethod] = useState('');
  const [selectedShipping, setSelectedShipping] = useState('');
  const [showSummary, setShowSummary] = useState(false); // Estado para mostrar/ocultar el resumen

  const cartTotal = cartItems.reduce((total, item) => total + item.precio * item.quantity, 0);
  const totalToPay = cartTotal + shippingCost;

  // Generar un ID de orden único
  const orderID = `order-${new Date().getTime()}`;

  const handleDeliveryChange = (method) => {
    setDeliveryMethod(method);
    setSelectedShipping(''); // Reiniciar selección de envío cuando cambia el método de entrega
  };

  const handleShippingChange = (option, price) => {
    setSelectedShipping(option);
    updateShippingCost(price); // Actualizar el costo de envío en el CartContext
  };

  const handlePayment = async () => {
    const totalToPay = cartTotal + shippingCost;
  
    try {
      const response = await fetch('https://gusitostore.cl/iniciar-transaccion-webpay.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          buyOrder: orderID,
          sessionId: `session-${orderID}`,
          amount: totalToPay,
          returnUrl: 'https://gusitostore.cl/payment-confirmation',
        }),
      });
  
      if (!response.ok) {
        throw new Error('Error en la respuesta del servidor');
      }
  
      const data = await response.json();
      window.location.href = `${data.url}?token_ws=${data.token}`;
    } catch (error) {
      console.error('Error durante la solicitud de pago: ', error);
    }
  };

  return (
    <div className="payment-module-container">
      {/* Mostrar el botón de resumen solo en la versión móvil */}
      <div className="mobile-summary-toggle" onClick={() => setShowSummary(!showSummary)}>
        <span>Mostrar resumen del pedido</span>
        <span className="summary-arrow">{showSummary ? '▲' : '▼'}</span>
        <span className="summary-total">${totalToPay.toLocaleString('es-CL')}</span>
      </div>

      {/* Mini resumen que se muestra u oculta en móviles */}
      {showSummary && (
        <div className="mobile-cart-summary">
          {cartItems.map((item) => (
            <div key={item.cartId} className="mini-cart-item">
              <span>{item.quantity} x {item.nombre}</span>
              <span>${(item.precio * item.quantity).toLocaleString('es-CL')}</span>
            </div>
          ))}
          <div className="mini-cart-total">
            <span>Total: </span>
            <span>${totalToPay.toLocaleString('es-CL')}</span>
          </div>
        </div>
      )}

      {/* Mitad izquierda */}
      <div className="payment-left">
        {/* Sección de contacto */}
        <h2>Contacto</h2>
        <input
          type="email"
          placeholder="Correo electrónico"
          value={contactEmail}
          onChange={(e) => setContactEmail(e.target.value)}
          className="contact-input"
        />
        <label className="subscribe-label">
          <input
            type="checkbox"
            checked={subscribe}
            onChange={() => setSubscribe(!subscribe)}
          />
          Enviarme novedades y ofertas
        </label>

        {/* Sección de entrega */}
        <h2>Entrega</h2>
        <div className="delivery-options">
          <div className={`delivery-option ${deliveryMethod === 'retiro' ? 'active' : ''}`} onClick={() => handleDeliveryChange('retiro')}>
            <span>Retiro en tienda</span>
            <span className="delivery-option-icon icon-store"></span>
          </div>
          <div className={`delivery-option ${deliveryMethod === 'envio' ? 'active' : ''}`} onClick={() => handleDeliveryChange('envio')}>
            <span>Envío</span>
            <span className="delivery-option-icon icon-shipping"></span>
          </div>
        </div>

        {/* Opciones de envío */}
        {deliveryMethod === 'envio' && (
          <div className="shipping-form">
            <h3>Métodos de envío</h3>
            <div className="delivery-options">
              <div className={`delivery-option ${selectedShipping === 'rm' ? 'active' : ''}`} onClick={() => handleShippingChange('rm', 3500)}>
                <span>Envío RM</span>
                <span className="delivery-price">$3.500</span>
              </div>
              <div className={`delivery-option ${selectedShipping === 'vregion' ? 'active' : ''}`} onClick={() => handleShippingChange('vregion', 4500)}>
                <span>Envío V Región</span>
                <span className="delivery-price">$4.500</span>
              </div>
              <div className={`delivery-option ${selectedShipping === 'chile' ? 'active' : ''}`} onClick={() => handleShippingChange('chile', 0)}>
                <span>Envío todo Chile</span>
                <span className="delivery-price">A pagar</span>
              </div>
            </div>
          </div>
        )}

        {/* Sección de pago */}
        <h2>Pago</h2>
        <h3>Todas las transacciones son seguras y están encriptadas</h3>
        <div className="payment-card">
          <img src="https://via.placeholder.com/150x50?text=Webpay+Plus" alt="Webpay Plus" />
          <p>
            Luego de hacer clic en “Pagar ahora”, serás redirigido a Mercado Pago para completar tu compra de forma segura.
          </p>
        </div>
      </div>

      {/* Mitad derecha */}
      <div className="payment-right">
        <h2>Resumen del pedido</h2>
        <div className="cart-items">
          {cartItems.map((item) => (
            <div key={item.cartId} className="cart-item">
              <div className="item-image-container">
                <span className="item-quantity">{item.quantity}</span>
                <img src={item.imagen} alt={item.nombre} className="item-image" />
              </div>
              <div className="item-details">
                <p>{item.nombre}</p>
                <p>Talla: {item.size}</p>
              </div>
              <p className="item-price">${(item.precio * item.quantity).toLocaleString('es-CL')}</p>
            </div>
          ))}
        </div>

        <div className="discount-code">
          <input type="text" placeholder="Código de descuento" className="discount-input" />
          <button className="apply-button">Aplicar</button>
        </div>

        <div className="order-summary">
          <p>Subtotal: ${cartTotal.toLocaleString('es-CL')}</p>
          <p>Envío: ${shippingCost ? shippingCost.toLocaleString('es-CL') : 'Gratis'}</p>
          <p className="total-price">Total: ${totalToPay.toLocaleString('es-CL')}</p>
        </div>

        <button className="pay-now-button" onClick={handlePayment}>Pagar ahora</button>
      </div>
    </div>
  );
};

export default PaymentModule;
