import React, { useState, useEffect, useContext } from 'react';
import { useParams, Link } from 'react-router-dom';
import { CartContext } from '../context/CartContext';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './ProductDetails.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRuler } from '@fortawesome/free-solid-svg-icons';

const ProductDetails = () => {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedSize, setSelectedSize] = useState(null);
  const [selectedColor, setSelectedColor] = useState(null); // Estado para el color seleccionado
  const [quantity, setQuantity] = useState(1);
  const { addToCart } = useContext(CartContext);
  const [mainImage, setMainImage] = useState('');
  const [availableSizes, setAvailableSizes] = useState([]); // Tallas disponibles con stock

  useEffect(() => {
    fetch(`https://gusitostore.cl/getProductDetails.php?id=${id}`)
      .then((response) => response.json())
      .then((data) => {
        setProduct(data.producto);
        setAvailableSizes(data.tallas.filter(talla => talla.stock > 0));
        setMainImage(data.producto.imagen);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching product details:', error);
        setLoading(false);
      });
  }, [id]);

  const handleSizeClick = (size) => {
    setSelectedSize(size);
  };

  const handleColorClick = (color) => {
    setSelectedColor(color);
  };

  const handleQuantityChange = (change) => {
    setQuantity((prevQuantity) => {
      const newQuantity = prevQuantity + change;
      if (newQuantity >= 1 && newQuantity <= 10) {
        return newQuantity;
      }
      return prevQuantity;
    });
  };

  const handleAddToCart = () => {
    if (!selectedSize || !selectedColor) {
      toast.warn('Por favor, selecciona una talla y un color antes de añadir al carrito', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    const productToAdd = {
      ...product,
      size: selectedSize,
      color: selectedColor, // Agregar el color seleccionado
      quantity: quantity,
    };

    addToCart(productToAdd, quantity);

    toast.success(`Producto agregado al carrito: Talla ${selectedSize}, Color ${selectedColor} (${quantity} unidades)`, {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handleImageClick = (image) => {
    setMainImage(image);
  };

  if (loading) {
    return <div>Cargando...</div>;
  }

  if (!product) {
    return <div>Producto no encontrado</div>;
  }

  return (
    <div className="product-details-container">
      <div className="product-images">
        <div className="main-image">
          <img src={mainImage} alt={product.nombre} className="product-main-img" />
        </div>
        <div className="additional-images">
          <img
            src={product.imagen}
            alt="Imagen 1"
            className="product-secondary-img"
            onClick={() => handleImageClick(product.imagen)}
          />
          {product.imagen2 && (
            <img
              src={product.imagen2}
              alt="Imagen 2"
              className="product-secondary-img"
              onClick={() => handleImageClick(product.imagen2)}
            />
          )}
          {product.imagen3 && (
            <img
              src={product.imagen3}
              alt="Imagen 3"
              className="product-secondary-img"
              onClick={() => handleImageClick(product.imagen3)}
            />
          )}
          {product.imagen4 && (
            <img
              src={product.imagen4}
              alt="Imagen 4"
              className="product-secondary-img"
              onClick={() => handleImageClick(product.imagen4)}
            />
          )}
        </div>
      </div>

      <div className="product-info-card">
        <h1 className="product-name">{product.nombre}</h1>
        <p className="product-price">
          ${parseInt(product.precio).toLocaleString('es-CL', { minimumFractionDigits: 0 })}
        </p>
        <p className="product-description">{product.descripcion}</p>

        <div className="size-guide-link">
          <Link to="/medidas">
            <FontAwesomeIcon icon={faRuler} className="icon-ruler" /> Consulta la guía de medidas
          </Link>
        </div>

        <div className='product-color'>

        <div className="product-sizes">
          <h4>TALLAS DISPONIBLES</h4>
          <div className="sizes-container">
            {availableSizes.length > 0 ? (
              availableSizes.map((size) => (
                <button
                  key={size.talla_id}
                  className={`size-button ${selectedSize === size.talla ? 'selected' : ''}`}
                  onClick={() => handleSizeClick(size.talla)}
                >
                  {size.talla}
                </button>
              ))
            ) : (
              <p>No hay tallas disponibles</p>
            )}
          </div>
          
        <h3>
          Talla elegida: <span className="size-box">{selectedSize ? selectedSize : '-'}</span>
        </h3>
        </div>
          
        {/* Mostrar colores disponibles como botones */}
        {product.colores && (
          <div className="product-colors">
            <h4>COLORES/ESTAMPADOS</h4>
            <div className="colors-container">
              {product.colores.split(',').map((color) => (
                <button
                  key={color}
                  className={`color-button ${selectedColor === color ? 'selected' : ''}`}
                  onClick={() => handleColorClick(color)}
                >
                  {color}
                </button>
              ))}
            </div>
        <h3 className='color-chosen'>
          Color elegido: <span className="color-box">{selectedColor ? selectedColor : '-'}</span>
        </h3>
          </div>
        )}
        </div>

        <div className="quantity-selector">
          <h3>Cantidad:</h3>
          <button
            className="quantity-button"
            onClick={() => handleQuantityChange(-1)}
            disabled={quantity <= 1}
          >
            -
          </button>
          <span className="quantity">{quantity}</span>
          <button
            className="quantity-button"
            onClick={() => handleQuantityChange(1)}
            disabled={quantity >= 10}
          >
            +
          </button>
        </div>

        <button
          className="add-to-cart-btn"
          onClick={handleAddToCart}
          disabled={!selectedSize || !selectedColor}
        >
          Añadir al carrito
        </button>
      </div>
    </div>
  );
};

export default ProductDetails;
