import React, { useState, useEffect } from 'react';
import './AddProduct.css';
import axios from 'axios';

const AddProduct = () => {
  const [nombre, setNombre] = useState('');
  const [precio, setPrecio] = useState('');
  const [descripcion, setDescripcion] = useState('');
  const [detalles, setDetalles] = useState('');
  const [tipo, setTipo] = useState('');
  const [colores, setColores] = useState(''); // Nuevo estado para colores
  const [categorias, setCategorias] = useState([]);
  const [tallas, setTallas] = useState([{ talla: '', stock: '' }]);

  const [imagenes, setImagenes] = useState({
    imagen1: null,
    imagen2: null,
    imagen3: null,
    imagen4: null,
  });

  const [showImage2, setShowImage2] = useState(false);
  const [showImage3, setShowImage3] = useState(false);
  const [showImage4, setShowImage4] = useState(false);

  useEffect(() => {
    fetch('https://gusitostore.cl/getCategories.php')
      .then((response) => response.json())
      .then((data) => {
        setCategorias(data);
      })
      .catch((error) => {
        console.error('Error al obtener las categorías:', error);
      });
  }, []);

  const handleTallaChange = (index, field, value) => {
    const updatedTallas = [...tallas];
    updatedTallas[index][field] = value;
    setTallas(updatedTallas);
  };

  const addTalla = () => {
    setTallas([...tallas, { talla: '', stock: '' }]);
  };

  const removeTalla = (index) => {
    setTallas(tallas.filter((_, i) => i !== index));
  };

  const handleFileChange = (e, imageName) => {
    setImagenes({
      ...imagenes,
      [imageName]: e.target.files[0],
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('nombre', nombre);
    formData.append('precio', precio);
    formData.append('descripcion', descripcion);
    formData.append('detalles', detalles);
    formData.append('tipo', tipo);
    formData.append('colores', colores); // Enviar colores como una cadena
    formData.append('tallas', JSON.stringify(tallas));
    formData.append('imagen', imagenes.imagen1);
    if (imagenes.imagen2) formData.append('imagen2', imagenes.imagen2);
    if (imagenes.imagen3) formData.append('imagen3', imagenes.imagen3);
    if (imagenes.imagen4) formData.append('imagen4', imagenes.imagen4);

    try {
      const response = await axios.post('https://gusitostore.cl/uploadProduct.php', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.data.success) {
        alert('Producto agregado exitosamente');
        // Limpiar los campos del formulario
        setNombre('');
        setPrecio('');
        setDescripcion('');
        setDetalles('');
        setColores(''); // Limpiar colores
        setImagenes({ imagen1: null, imagen2: null, imagen3: null, imagen4: null });
        setTipo('');
        setTallas([{ talla: '', stock: '' }]);
        setShowImage2(false);
        setShowImage3(false);
        setShowImage4(false);
      } else {
        alert('Error al agregar el producto: ' + response.data.message);
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Hubo un problema al enviar los datos. Intenta nuevamente.');
    }
  };

  return (
    <div className="add-product-container">
      <h2>Agregar Producto</h2>
      <form className="add-product-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Nombre:</label>
          <input type="text" value={nombre} onChange={(e) => setNombre(e.target.value)} required />
        </div>

        <div className="form-group">
          <label>Precio:</label>
          <input type="number" value={precio} onChange={(e) => setPrecio(Number(e.target.value))} required />
        </div>

        <div className="form-group">
          <label>Descripción:</label>
          <textarea value={descripcion} onChange={(e) => setDescripcion(e.target.value)} required />
        </div>

        <div className="form-group">
          <label>Detalles:</label>
          <textarea value={detalles} onChange={(e) => setDetalles(e.target.value)} required />
        </div>

        {/* Colores */}
        <div className="form-group">
          <label>Colores (opcional, separados por comas):</label>
          <input
            type="text"
            value={colores}
            onChange={(e) => setColores(e.target.value)}
            placeholder="Ejemplo: rojo, azul, verde"
          />
        </div>

        {/* Tallas y Stock */}
        <div className="form-group">
          <label>Tallas y Stock:</label>
          {tallas.map((tallaItem, index) => (
            <div key={index} className="talla-group">
              <input
                type="text"
                placeholder="Talla"
                value={tallaItem.talla}
                onChange={(e) => handleTallaChange(index, 'talla', e.target.value)}
                required
              />
              <input
                type="number"
                placeholder="Stock"
                value={tallaItem.stock}
                onChange={(e) => handleTallaChange(index, 'stock', e.target.value)}
                required
              />
              {index > 0 && (
                <button type="button" onClick={() => removeTalla(index)}>
                  Quitar
                </button>
              )}
            </div>
          ))}
          <button type="button" onClick={addTalla}>Agregar otra talla</button>
        </div>

        <div className="form-group">
          <label>Imagen 1:</label>
          <input type="file" onChange={(e) => handleFileChange(e, 'imagen1')} required />
        </div>

        {/* Segunda Imagen */}
        {showImage2 ? (
          <div className="form-group">
            <label>Imagen 2:</label>
            <input type="file" onChange={(e) => handleFileChange(e, 'imagen2')} />
          </div>
        ) : (
          <button type="button" onClick={() => setShowImage2(true)}>
            ¿Agregar otra imagen?
          </button>
        )}

        {/* Tercera Imagen */}
        {showImage3 ? (
          <div className="form-group">
            <label>Imagen 3:</label>
            <input type="file" onChange={(e) => handleFileChange(e, 'imagen3')} />
          </div>
        ) : (
          showImage2 && (
            <button type="button" onClick={() => setShowImage3(true)}>
              ¿Agregar otra imagen?
            </button>
          )
        )}

        {/* Cuarta Imagen */}
        {showImage4 ? (
          <div className="form-group">
            <label>Imagen 4:</label>
            <input type="file" onChange={(e) => handleFileChange(e, 'imagen4')} />
          </div>
        ) : (
          showImage3 && (
            <button type="button" onClick={() => setShowImage4(true)}>
              ¿Agregar otra imagen?
            </button>
          )
        )}

        <div className="form-group">
          <label>Tipo (Categoría):</label>
          <select value={tipo} onChange={(e) => setTipo(e.target.value)} required>
            <option value="">Seleccionar categoría</option>
            {categorias.map((categoria) => (
              <option key={categoria.id} value={categoria.id}>
                {categoria.nombre}
              </option>
            ))}
          </select>
        </div>

        <button type="submit" className="add-product-btn">Agregar Producto</button>
      </form>
    </div>
  );
};

export default AddProduct;
